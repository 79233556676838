import React from "react";
import "./InternationalConference.css";
import pdfBroucher from "./Brochure ICIADR.pdf#toolbar=0";
import Iframe from "react-iframe";

const InternationalConference = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="g20-maha-food-festival-heading">
        International Conference held on 9th & 10th Dec 2024
      </h2>
      <div className="g20-maha-food-festival-paragrauph">
        The International Conference on Innovations in Advanced Drug Research
        (ICIADR) 2024, hosted by Delhi Pharmaceutical Sciences and Research
        University (DPSRU) on December 9-10, 2024, emerged as a landmark event
        in pharmaceutical research and innovation. Under the leadership of
        Conference Convenor Prof. Ravichandiran V., Vice Chancellor DPSRU, and
        Conference Co- Convenor Prof. Harvinder Popli, along with Conference
        Coordinators Dr. Minakshi Garg, Associate Professor DPSRU and Ms. Ruchi
        Singh Neekhra, Program Lead DIIF, the conference successfully brought
        together over 270 participants representing 27 universities and
        institutions from 8 states. <br />
        <br />
        The conference was inaugurated by distinguished leaders, including Dr.
        Rajeev Singh Raghuvanshi, Drugs Controller General of India, whose
        presence underscored the event's significance in shaping India's
        pharmaceutical future. Other notable dignitaries included Dr. Raj K
        Shirumalla, Mission Director of the National Biopharma Mission, and Dr.
        C.N. Ramchand, CEO of MagGenome Technologies Pvt. Ltd. <br />
        <br />
        A major highlight was the extensive international collaboration with
        Auburn University, USA, featuring six expert sessions that showcased
        cutting-edge advancements in pharmaceutical research. These sessions,
        led by distinguished faculty, covered crucial areas such as structure-
        based drug design by Dr. Meenakshi Singh, novel treatments for
        Alzheimer's disease by Dr. Rajesh Amin, engineered tissues for
        regenerative medicine by Dr. Elizabeth Lipke, and innovations in
        3D-printed implants by Dr. Jayachandra Babu Ramapuram. <br />
        <br />
        The conference featured 160+ poster presentations across diverse
        thematic areas, including advanced drug delivery systems and artificial
        intelligence in drug research. A pivotal panel discussion on
        "Transforming Research into Healthcare Startups" brought together
        industry experts who shared valuable insights on bridging the gap
        between research and commercial implementation. Ms. Arushi Jain
        highlighted startup-industry collaboration possibilities, while Dr.
        Pooja Sharma addressed the challenges of translating early-stage
        innovations into practical healthcare solutions. <br />
        <br />
        Technical sessions further enriched the conference with Dr. Ashish Arora
        providing crucial insights on bench-to-market transition, Prof. Rajkumar
        M discussing pharmacokinetics models, and Dr. RK Rao presenting
        innovative research on probiotic formulations for gastrointestinal
        health. <br />
        <br />
        The conference aligned perfectly with India's pharmaceutical sector
        trajectory, which is projected to reach $65 billion by 2024 and $130
        billion by 2030. This growth is supported by government initiatives like
        Production-Linked Incentive schemes and the development of research hubs
        like Genome Valley in Hyderabad. ICIADR 2024 successfully fostered
        meaningful connections between academia, industry, and innovators,
        contributing to the advancement of pharmaceutical research and
        healthcare innovation in India. <br />
        <br />
      </div>

      <div className="pdf-broucher-view-box">
        <iframe
          src={pdfBroucher}
          // width="100%"
          // height="600px"
          title="PDF Viewer"
        ></iframe>
      </div>

      <div className="image-container-of-internation-conference">
        <h2 className="dpsru-secondary-heading-for-every-page">
          Photos Gallery
        </h2>
        {/* image container */}
        <h2 className="inner-gallery-text-of-ic">Day 01</h2>
        <div className="workshop-gallery-container">
          <img src="" alt="International Conference" />
          <img src="" alt="International Conference" />
          <img src="" alt="International Conference" />
        </div>

        <h2 className="inner-gallery-text-of-ic">Day 02</h2>
        <div className="workshop-gallery-container">
          <img src="" alt="International Conference" />
          <img src="" alt="International Conference" />
          <img src="" alt="International Conference" />
        </div>
      </div>
      <div className="media-coverage-container-of-international-conference">
        <h2 className="dpsru-secondary-heading-for-every-page">
          Media Coverage
        </h2>
        <div className="youtube-media-covarage-container">
          <div className="youtube-media-covarage-box">
            <Iframe
              url="https://www.youtube.com/embed/zceCtHNLk0E?si=ug8KD3pAhwwgH_Fe"
              width="100%"
              height="100%"
              id=""
              className=""
              display="block"
            />
          </div>
          <div className="youtube-media-covarage-box">
            <Iframe
              url="https://www.youtube.com/embed/ZHf-xgCLMtQ?si=-KFgl0BFAGHe8FYa"
              width="100%"
              height="100%"
              id=""
              className=""
              display="block"
            />
          </div>
          <div className="youtube-media-covarage-box">
            <a href="https://drugtodayonline.com/medical-news/news-topic/20782-dpsru-conference-brings-together-global-experts-to-shape-future-of-healthcare">
              {" "}
              <img
                src="https://drugtodayonline.com/assets/front/images/article_images/th-1733811297.jpg"
                alt="drug control"
              />
            </a>{" "}
            <p>
              DPSRU Conference Brings Together Global Experts to Shape Future of
              Healthcare (drugtodayonline.com)
            </p>
          </div>{" "}
          <br />
        </div>{" "}
        <br /> <br />
      </div>
    </div>
  );
};

export default InternationalConference;
