import React from 'react'
import dpsruLogo from "../../../A tabs creation/TabFiles/WhatsNew/G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/dpsru-logo.png";

import amritMahotsav from "../../../A tabs creation/TabFiles/WhatsNew/G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/75-azadi-ka-amrit-mahotsav.png";
import nssLogo from "../../../A tabs creation/TabFiles/WhatsNew/G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/national-service-scheme.png";

import u1 from './u1.jpg'
import u2 from './u2.jpg'


const UnnatBharatAbhiyan = () => {
  return (
    <div className="G20MahaFoodFestival-component">
      <div className="upper-logo-container-of-g20-maha-food-festival">
        <img src={dpsruLogo} alt="Dpsru Logo" />
        <img src={amritMahotsav} alt="amrit Mahotsav" />
        <img src={nssLogo} alt="Nss" />
      </div>

      <h1 className="g20-maha-food-festival-heading">
      Unnat Bharat Abhiyan cell, DPSRU Donated Chairs to the needy children of Anganwadi School, Deoli village
      </h1>
     <div className='g20-maha-food-festival-paragrauph' style={{margin:'3% 0'}}>
     Unnat Bharat Abhiyan, Delhi Pharmaceutical Sciences and Research University adopted Deoli village for the development, awareness of health and education in Delhi. Anganwadi school is urban village child care center situated in Deoli, South Delhi. This school serves for urban village and low income family kids below poverty line to give education, food and other necessities for 30+ kids. But there, it we see that there is no arrangement for the children to sit; children study by sitting on the ground even in winter. Anganwadi staffs also told us about the chairs are not available to sit, then we decide to help the childrens. <br /><br />

Unnat Bharat Abhiyan cell, DPSRU adopted this village for the development, awareness of health and education in Delhi. Honourable Prof. Ramesh K Goyal, Vice Chancellor and Respected Dr.O.P.Shukla, Registrar, DPSRU donated the 30+kid chairs, fans, LED tube sets to the Anganwadi school received by Mrs. Santhoshi, Anganwadi Worker, Deoli, South Delhi. The Program was coordinated by Dr.J.Swaminathan, UBA Coordinator, DPSRU, Delhi  

<h2 className="galery-heading-of-g-20-maha-food-f">Gallery</h2>
<div className="image-container-of-g-20-gallery">
    <img src={u1} alt="unnat bharat" />
    <img src={u2} alt="unnat bharat" />
</div>
     </div>
     </div>
  )
}
export default UnnatBharatAbhiyan