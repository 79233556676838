import React from "react";
import "./Events.css";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import G20CycleRally from "./Events Data/G20-CYCLE-RALLY-EVENT.pdf";
import panelDiscussionToInvestInPeople from "./Events Data/Panel-Discussion-new-.pdf";
import G20IndoBrazilFusionFest from "./Events Data/G20 Indo Brazil Fusion Fest.pdf";
import REPORTonPUSHPPRADARSHNAM2023 from "./Events Data/REPORT on PUSHP PRADARSHNAM 2023.pdf";
import G20MahafoodfestivalofChinaFranceGermanyatDPSRU from "./Events Data/G20 Maha food festival of China, France & Germany at DPSRU.pdf";
import G20QuizCompetitionatDPSRU from "./Events Data/G20 Quiz Competition at DPSRU.pdf";
import Conference2020 from "./Events Data/Conference 2020.pdf";
import Conference2022 from "./Events Data/Conference 2022.pdf";
import WorkshoponAdjunctMechanisminOnFieldInjuryRehabilitationKinesiotapingOn13thFeb2023 from "./Events Data/Workshop on Adjunct Mechanism in On Field Injury Rehabilitation Kinesiotaping On 13th Feb 2023.pdf";
import NationalScienceDay28thFeb2023 from "./Events Data/National Science Day 28th Feb 2023.pdf";
import UnnatBharatAbhiyancellDPSRUDonatedChairstotheneedychildrenofAnganwadiSchoolDeolivillage from "./Events Data/Unnat Bharat Abhiyan cell, DPSRU Donated Chairs to the needy children of Anganwadi School, Deoli village.pdf";
import Vicechancellorfelicitetedataiims from "./Events Data/Honble Vice Chancellor Prof. V. Ravichandiran felicitated at AIIMS.pdf";
import internationConf24 from "./Events Data/Where innovation meets reflection.pdf";
import bbsReport from './Events Data/BBS REPORT 1.pdf'
import FitIndiaReport from './Events Data/Fit India Report.pdf'

const Events = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">EVENTS</h2>

      <div className="nirf-data-container">
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="confrence"
            smooth={true}
            duration={500}
            className="nirf-botton library-navlink library-navlink"
          >
            Conferences
          </Link>
        </section>

        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="workshop"
            smooth={true}
            duration={500}
            className="nirf-botton library-navlink library-navlink"
          >
            Workshop
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each ">
          <Link
            to="g20event"
            smooth={true}
            duration={500}
            className="nirf-botton library-navlink"
          >
            G20 Events
          </Link>
        </section>
      </div>

      <div className="event-data-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Event</h2>

        <ul className="evnet-page-ul-container">

        <li className="evnet-page-list">
           <NavLink to='/international-Conference-2024'  className="event-a-click-here" style={{color:'black', fontWeight:'bold'}}>International Conference 2024</NavLink>
          </li>{" "}
        <li className="evnet-page-list">
            <a
              href='https://www.sdi2025.in' target="_blank" rel="noreferrer"
              className="event-files-link"
            >
            Smart Delhi Ideathon 2025
            </a> 
          </li>{" "}


        <li className="evnet-page-list">
            <a
              href={FitIndiaReport}
              className="event-files-link"
            >
             6th Edition of Fit India Week Scheduled to be Celebrated from 15th November to 31st December 2024
            </a> 
          </li>{" "}

          <li className="evnet-page-list">
           <NavLink to='/clinical-Pharmacology-and-Translational-Research'  className="event-a-click-here" style={{color:'black', fontWeight:'bold'}}>Clinical Pharmacology and Translational Research</NavLink>
          </li>{" "}


          <li className="evnet-page-list">
            <a
              href={bbsReport}
              className="event-files-link"
            >
             DPSRU Proudly Participated in Business Blaster Schemes (Seniors)
             launch event at IIIT -Delhi
            </a> <NavLink to='/business-blaster-2024'  className="event-a-click-here">Click here</NavLink>
          </li>{" "}



          <li className="evnet-page-list">
            <a
              href={Vicechancellorfelicitetedataiims}
              className="event-files-link"
            >
              Hon'ble Vice Chancellor Prof. V. Ravichandiran felicitated at
              AIIMS, New Delhi by the Director AIIMS, Prof. M. Srinivas.
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={NationalScienceDay28thFeb2023}
              className="event-files-link"
            >
              National Science Day 28th Feb 2023
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={
                UnnatBharatAbhiyancellDPSRUDonatedChairstotheneedychildrenofAnganwadiSchoolDeolivillage
              }
              className="event-files-link"
            >
              Unnat Bharat Abhiyan cell, DPSRU Donated Chairs to the needy
              children of Anganwadi School, Deoli village
            </a> <NavLink to='/unnatBharatAbhiyan'  className="event-a-click-here">Click here</NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a href="/Swachhta-Hi-seva" className="event-files-link">
              Swachhta Hi seva – Ek Tareek- Ek Ghante at DPSRU Campus on
              01.10.2023
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a href="/world-heart-day-29-sept" className="event-files-link">
              IACS India Section and DPSRU Celebrated World Heart Day
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a href="/chintan-shivir" className="event-files-link">
              CHINTAN SHIVIR
            </a>
          </li>
          <li className="evnet-page-list">
            <a href='/pushpPradarshnam' className="event-files-link">
            PUSHP PRADARSHNAM 2023
            </a>
          </li>
        </ul>
      </div>

      {/* Conferences */}

      <div className="event-data-container" id="confrence">
        <h2 className="dpsru-secondary-heading-for-every-page">Conferences</h2>
        <ul className="evnet-page-ul-container">
          <li className="evnet-page-list">
            <a href={Conference2020} className="event-files-link">
              Conference 2020
            </a>{" "}
          </li>{" "}
          <li className="evnet-page-list">
            <a href={Conference2022} className="event-files-link">
              Conference 2022
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a href='/conference' className="event-files-link">
            More
            </a>
          </li>{" "}
        </ul>
      </div>

      {/* Workshops */}
      <div className="event-data-container" id="workshop">
        <h2 className="dpsru-secondary-heading-for-every-page">Workshop</h2>
        <ul className="evnet-page-ul-container">
        <li className="evnet-page-list">
        <a
          href={
            WorkshoponAdjunctMechanisminOnFieldInjuryRehabilitationKinesiotapingOn13thFeb2023
          }
          className="event-files-link"
        >
      Adjunct Mechanism in On Field Injury Rehabilitation:
          Kinesiotaping
        </a>  <NavLink to='/workshop'  className="event-a-click-here">Click here</NavLink></li></ul>
      </div>

      {/* G20 */}

      <div className="event-data-container" id="g20event">
        <h2 className="dpsru-secondary-heading-for-every-page">G20 Events</h2>

        <ul className="evnet-page-ul-container">
          <li className="evnet-page-list">
            <a href={internationConf24} className="event-files-link">
              Where innovation meets reflection—collaborating for a healthier
              tomorrow during ICIADR-24 held at DPSRU, December , 9-10, 2024
            </a> <NavLink to='/g20'  className="event-a-click-here">Click here</NavLink>
          </li>
          <li className="evnet-page-list">
            <a href={G20CycleRally} className="event-files-link">
              G20 CYCLE RALLY at “Qutub Minar” on 25th May 2023
            </a> <NavLink to='/g20'  className="event-a-click-here">Click here</NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={panelDiscussionToInvestInPeople}
              className="event-files-link"
            >
              Panel Discussion @DPSRU on “TO INVEST IN PEOPLE, PRIORITIZE
              EDUCATION” on 24.01.2023
            </a> <NavLink to='/g20PanelDiscussionReport'  className="event-a-click-here">Click here</NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a href={G20IndoBrazilFusionFest} className="event-files-link">
              G20 Indo Brazil Fusion Fest
            </a> <NavLink to='/indoBrazilFusionFest'  className="event-a-click-here">Click here</NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a href={REPORTonPUSHPPRADARSHNAM2023} className="event-files-link">
              REPORT on PUSHP PRADARSHNAM 2023
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={G20MahafoodfestivalofChinaFranceGermanyatDPSRU}
              className="event-files-link"
            >
              G20 Maha food festival of China, France & Germany at DPSRU
            </a> <NavLink to='/G20MahaFoodFestival'  className="event-a-click-here">Click here</NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a href={G20QuizCompetitionatDPSRU} className="event-files-link">
              G20 Quiz Competition at DPSRU
            </a> <NavLink to='/g20QuizCompetition'  className="event-a-click-here">Click here</NavLink>
          </li>
         
        </ul>
      </div>
    </div>
  );
};

export default Events;
