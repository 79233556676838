import React from 'react'

import cptr1 from './Cptr1.jpg'
import cptr2 from './cptr2.jpg'
import cptr3 from './cptr3.jpg'
import flyer from './Flyer .pdf'

const ClinicalPharmacologyandTranslationalResearch = () => {
  return (
    <div className='dpsru-every-component-css'>
    <h2 className='g20-maha-food-festival-heading'>“Clinical Pharmacology and Translational Research”</h2>
    <div className="g20-maha-food-festival-paragrauph">
    The International Symposium on “Clinical Pharmacology and Translational Research” was
organized by Delhi Pharmaceutical Sciences and Research University (DPSRU) in
collaboration with ISPOR India Chapter of the DPSRU. The main objective of the
symposium was to bring together academic professionals, industry experts, and researchers to
discuss critical advancements in clinical pharmacology and translational research, fostering
collaborative learning and professional networking. Dr Rajani Mathur, Assistant Professor,
Department of Pharmacology and Member Secretary of ISPOR India Chapter welcomed the
gathering and introduced briefly about the ISPOR India Chapter. The inauguration of the
event was done which includes the lighting of the lamp, a gesture symbolizing knowledge
and enlightenment, followed by the felicitation of guests. 04 scientific sessions were
conducted in the morning sessions. The brief detail is as follows: Dr. Rajneet Oberoi,
Scientific Associate Director, Amgen, USA, provided a detailed overview of clinical
pharmacology, focusing on its role in understanding drug action, efficacy, and safety. Dr.
Harsh Negi, Delivery Manager - Clinical Pharmacology, TCS, India emphasized the
importance of model-based approaches in drug development, particularly in improving
efficiency and reducing uncertainties in drug research. Dr. Mukul Minocha, Director, Clinical
Pharmacology, Amgen, USA highlighted the growing role of pharmacometrics in drug
development, presenting its applications in personalized medicine and regulatory decision-
making. Dr. Anoop Kumar, Assistant Professor, DPSRU, India covered the economic
evaluation of healthcare interventions and its significance in ensuring cost-effective
therapeutic outcomes. The panel discussion on benefits and approaches to integrate
Pharmacometrics with Pharmacoeconomics was also conducted. The afternoon session
provided an in-depth understanding of drug repurposing for rare diseases, addressing the
challenges and opportunities in navigating regulatory pathways and clinical trials. The keynote speaker of the session is: Dr. Vivek Gupta, Associate Professor, St. John’s University,
USA. The concluding remarks were provided by the Prof (Dr.) Ravichandiran V, Hon’ble
Vice-Chancellor of DPSRU, emphasized the importance of clinical Pharmacology in modern
healthcare and its role in bridging the gap between research and practice. The Chief Guest
Dr. Shridhar Dwivedi, well known cardiologist, National Heart Institute (NHI), Delhi also
motivated the audience. The event was attended by faculty members, Master students of
Pharmacology, Clinical Research, Biotechnology, Physiotherapy, Allied Health Sciences,
Hospital and Clinical Pharmacy students. The symposium ended with a Vote of Thanks by
Dr. Anoop Kumar, who expressed gratitude to each and every one followed by National
Anthem and group photograph. Some of the glimpses of the events are mentioned below:
    </div>



    <div className="flyer-of-cptr" style={{width:"80%", margin:'2% auto'}}>
     Flyer :-   <a href={flyer} style={{color:'blue', fontWeight:'bold', textDecoration:'none'}}> Click here</a>
    </div>

    <h2 className="dpsru-secondary-heading-for-every-page">
      GALLERY
    </h2>

    <div className="workshop-gallery-container">
      <img src={cptr1} alt="Business Blaster" />
      <img src={cptr2} alt="Business Blaster" />
      <img src={cptr3} alt="Business Blaster" />
    </div>


    </div>
  )
}

export default ClinicalPharmacologyandTranslationalResearch
