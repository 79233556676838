import React from 'react'
    import g20img1 from './G20 data/g20img1.jpeg'
    import g20img2 from './G20 data/g20img2.jpeg'
    import g20img3 from './G20 data/g20img3.jpeg'
    import g20img4 from './G20 data/g20img4.jpeg'
    import g20img5 from './G20 data/g20img5.jpeg'

const G20 = () => {
  return (
    <div className='dpsru-every-component-css'>
    <h2 className='g20-maha-food-festival-heading'>G20 CYCLE RALLY at “Qutub Minar” on 25th May 2023</h2>
    <div className="g20-maha-food-festival-paragrauph">
   <b> Delhi Pharmaceutical Sciences and Research University</b> organized <b> G20 Cycle Rally Event </b> at Qutub Minar, celebrating India’s G20 Presidency and spreading awareness about Environment conservation and promoting the use of cycles as green public transport for travelling short distances especially by college students. <br /> <br />

The rally was organized as one of the G20 events by the student coordinators Manvi Ambastha and Aman Kumar, B Pharm 2nd year students under the guidance of Dr. Saurabh Dahiya, G20 Nodal Officer and the Faculty Coordinators Dr. Vijender Kumar and Dr. Sahil Kumar with the support of the NSS volunteers. The program was envisioned by the Hon’ble Vice Chancellor Prof. Ramesh K. Goyal, and administrative support was provided by Registrar Dr. O. P. Shukla. <br /> <br />

Approximately 50 participants took part in the G20 cycle rally. They assembled near the famous historical monument in New Delhi, which is the Qutub Minar. Students were given sun protecting caps which were specially customized for the event showcasing G20 logo and DPSRU logo. The rally was started from the main gate of Qutub Minar and the students traveled on their cycles from there to the college campus reciting the slogan “Jan Jan Ko Batlana hai, Cycle ko Aapnana hai”. <br /> <br />
 
Student cyclists carried with them the Flags of G20 countries for spreading awareness about India’s G20 presidency, whereas the accompanying faculty members were carrying the placards showing logo of G20. Tourists visiting Qutub Minar were seen taking interest and pride in the G20 activity. <br /> <br />

After returning to the college campus, healthy refreshments were distributed to the participants along with water for hydration in the scorching summer of the city. All the participants were given certificates for appreciation and mementos for actively taking part in the rally.
    </div>

    <h2 className="dpsru-secondary-heading-for-every-page">
      GALLERY
    </h2>

    <div className="workshop-gallery-container">
      <img src={g20img1} alt="Workshop" />
      <img src={g20img2} alt="Workshop" />
      <img src={g20img3} alt="Workshop" />
      <img src={g20img4} alt="Workshop" />
      <img src={g20img5} alt="Workshop" />
    
    </div>
    </div>
  )
}

export default G20
