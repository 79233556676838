import React from 'react'
import quiz from './quiz.jpg'
import dpsruLogo from "../../../A tabs creation/TabFiles/WhatsNew/G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/dpsru-logo.png";

import amritMahotsav from "../../../A tabs creation/TabFiles/WhatsNew/G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/75-azadi-ka-amrit-mahotsav.png";
import nssLogo from "../../../A tabs creation/TabFiles/WhatsNew/G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/national-service-scheme.png";


const G20QuizCompetition = () => {
  return (
    <div className="G20MahaFoodFestival-component">
      <div className="upper-logo-container-of-g20-maha-food-festival">
        <img src={dpsruLogo} alt="Dpsru Logo" />
        <img src={amritMahotsav} alt="amrit Mahotsav" />
        <img src={nssLogo} alt="Nss" />
      </div>

      <h1 className="g20-maha-food-festival-heading">
      G20 Quiz Competition at DPSRU
      </h1>
     <div className='g20-maha-food-festival-paragrauph' style={{margin:'3% 0'}}>DPSRU, New Delhi organised G20 Quiz competition on April 26th, 2023 to celebrate India’s G 20 presidency. A total of 32 teams participated in the quiz competition, and each team consisted of four members. The competition was held in three rounds: the preliminary round, semi-final round, and final round. The event coordinators Dr. Sachin Kumar and Dr. Bharti Mangla said that the questions of the quiz covered a wide range of topics, including basic science, computers, geography, politics, civics, history, culture of India and G20 countries. The G20 Nodal Officer Dr. Saurabh Dahiya engaged with the students to uplift their awareness about latest developments in the G20 arena. The winning teams were awarded cash prizes and certificates by the Hon’ble Vice Chancellor Prof. Ramesh K. Goyal. The Registrar Dr. O.P. Shukla provided administrative support for the event. The event was not only a great opportunity for students to showcase their knowledge and skills, but also to celebrate India’s presidency of the G20 group. As a major global economic forum, the G20 group plays a critical role in shaping the future of the global economy, and India’s presidency of the group is a testament to the country’s growing influence and importance in the world. Overall, the quiz competition was a fun and engaging way to celebrate India’s presidency of the G20 group, while also providing a valuable opportunity for students to learn and grow. The university is planning many more student oriented G20 related activities.
     <div style={{textAlign:"center", margin:'2% auto'}}>

     <img src={quiz} alt="Quiz pic" />
     </div>
     </div>
    </div>

  )
}

export default G20QuizCompetition
