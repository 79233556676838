import React from 'react'
import './Administration.css'
import administration from './AdministrationImg.jpeg'
import { Link } from 'react-router-dom'

import maintainanceofhteattandance from './Office Order/Maintence of Attendance of the faculty and non-teaching Staff.jpeg'
import committeeforthephysicalverification from './Office Order/Commitees for conducting physical verification.pdf'

function Administration() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">Administration</h2>
            <h4 className="administration-heading-sub">7 Pillars of Good Governance</h4>
            <div className="img-box-of-administration">
                <img src={administration} alt="administration" />
            </div>

            <div className="administration-section-container ce-container-box">
                <h4 className="administration-heading-sub-2">
                    Administration</h4>

                <ul >
                    <li >
                        <Link to="/genralCounsil">Governing Board</Link>
                    </li>
                    <li>
                        <Link to="/staff">Administrative Staff</Link>
                    </li>
                    <li >
                        <Link to="/organogram">Organogram</Link>
                    </li>
                    <li >
                        <Link to='/committees'>Committees
                        </Link>
                    </li>
                    <li >
                        <Link to="/tenders">Tenders</Link>
                    </li>
    
                    <li >
                        <a href={maintainanceofhteattandance}>Maintence of Attendance of the faculty and non-teaching Staff</a>
                    </li>
                    <li >
                        <a href={committeeforthephysicalverification}>Commitees for conducting physical verification</a>
                    </li>
                </ul>

            </div>

        </div>
    )
}

export default Administration