import React from "react";
import { Link } from "react-router-dom";
import newgif from "../../NEW/new.gif";

// data
import DateSheetofBScHonsSportsScience2ndSemesterExaminationsSep2023pdf from "./Datesheet Data/Date-Sheet-of-B.-Sc.-Hons.-Sports-Science-2nd-Semester-Examinations-Sep-2023.pdf";
import DateSheetofMScSportsScience2nd4thSemesterExaminationSep2023 from "./Datesheet Data/Date-Sheet-of-M.-Sc.-Sports-Science-2nd-4th-Semester-Examination-Sep-2023.pdf";
import PracticalDateSheetofBScHonsBiomedicalScience2ndSemesterExaminationSeptember2023 from "./Datesheet Data/Practical-Date-Sheet-of-B.Sc_.-Hons.-Biomedical-Science-2nd-Semester-Examination-September-2023.pdf";
import DateSheetofMBAPM2ndSemesterSupplementaryExaminationSeptember2023 from "./Datesheet Data/Date-Sheet-of-MBA-PM-2nd-Semester-Supplementary-Examination-September-2023.pdf";
import DateSheetofMBAPET2ndSemesterSupplementaryExaminationSeptember2023 from "./Datesheet Data/Date-Sheet-of-MBA-PET-2nd-Semester-Supplementary-Examination-September-2023.pdf";
import DateSheetofMScArtificialIntelligenceinMedicine2ndSemesterTheoryExaminationSeptember2023 from "./Datesheet Data/Date-Sheet-of-M.Sc_.-Artificial-Intelligence-in-Medicine-2nd-Semester-Theory-Examination-September-2023.pdf";
import DateSheetofBScHonsSportsScience4thSemesterPracticalExaminationSep2023 from "./Datesheet Data/Date-Sheet-of-B.-Sc.-Hons.-Sports-Science-4th-Semester-Practical-Examination-Sep-2023.pdf";
import DatesheetofMBAITMMBAPMMBAHHMMPH2NDSemesterExaminationSeptember2023 from "./Datesheet Data/Datesheet-of-MBA-ITM-MBA-PM-MBA-HHM-MPH-2ND-Semester-Examination-September-2023.pdf";
import PracticalDatesheetofBpharm6thsemesterDIPSARSeptember2023 from "./Datesheet Data/Practical-Datesheet-of-B.-pharm-6th-semester-DIPSAR-September2023.pdf";
import DatesheetofDMLTBMLT4thSemesterExaminationSeptember2023 from "./Datesheet Data/Date-sheet-of-DMLT-BMLT-4th-Semester-Examination-September-2023.pdf";
import DateSheetofDMLTBMLT2ndSemesterSupplementaryOldSchemeExaminationSept2023 from "./Datesheet Data/Date-Sheet-of-DMLT-BMLT-2nd-Semester-Supplementary-Old-Scheme-Examination-Sept-2023.pdf";
import DatesheetofDMLTBMLT2ndSemesterExaminationSeptember20232 from "./Datesheet Data/Date-sheet-of-DMLT-BMLT-2nd-Semester-Examination-September-2023-2.pdf";
import DATESHEETOFBPHARM6THSEMESTERPRACTICALEXAMINATIONSPSSEPTEMBER2023 from "./Datesheet Data/DATESHEET-OF-B.PHARM-6TH-SEMESTER-PRACTICAL-EXAMINATION-SPS-SEPTEMBER-2023.pdf";
import DateSheetofBScHonsBiomedicalScience2ndSemesterTheoryExaminationSeptember2023 from "./Datesheet Data/Date-Sheet-of-B.Sc_.-Hons.-Biomedical-Science-2nd-Semester-Theory-Examination-September-2023.pdf";
import CORRIGENDUMOFBPHARM2NDSEMESTEREXAMINATION20233 from "./Datesheet Data/CORRIGENDUM-OF-B.PHARM-2ND-SEMESTER-EXAMINATION-2023-3.pdf";
import DatesheetofMpharm2ndsemesterSuppleTheoryExaminationSPSAugust2023 from "./Datesheet Data/Datesheet-of-M.pharm-2nd-semester-Supple.-Theory-Examination-SPS-August-2023.pdf";
import DatesheetBBACH2ndSemesterSupplySept2023 from "./Datesheet Data/Date-sheet-BBA-CH-2nd-Semester-Supply-Sept-2023.pdf";
import DatesheetBBAHCBBACH2nd4thSemestersept2023 from "./Datesheet Data/Date-sheet-BBA-HCBBA-CH-2nd-4th-Semester-sept-2023.pdf";
import DatesheetofBPharm6thSemesterTheoryExaminationAugust20231 from "./Datesheet Data/Datesheet-of-B.Pharm-6th-Semester-Theory-Examination-August-2023-1.pdf";
import DateSheetofBScHonsSportsScience4thSemesterExaminationAug2023 from "./Datesheet Data/Date-Sheet-of-B.-Sc.-Hons.-Sports-Science-4th-Semester-Examination-Aug-2023.pdf";
import DateSheetofBPT6th8thSemesterPracticalExaminationJuly2023 from "./Datesheet Data/Date-Sheet-of-BPT-6th-8th-Semester-Practical-Examination-July-2023.pdf";
import DateSheetofBScHonsSportsScience6thSemesterExaminationJuly2023 from "./Datesheet Data/Date-Sheet-of-B.-Sc.-Hons.-Sports-Science-6th-Semester-Examination-July-2023.pdf";
import DatesheetofDpharm1styearAnnualSupplementaryPracticalJuly202311 from "./Datesheet Data/Date-sheet-of-D.pharm_.-1st-year-Annual-Supplementary-Practical-July-2023_1-1.pdf";
import RevisedDatesheetofBPharm8thSemProjectVivaVoceExaminationJuly2023SPS13ba7f19923da2c078ef from "./Datesheet Data/Revised-Datesheet-of-B.Pharm-8th-Sem-Project-Viva-Voce-Examination-July-2023-SPS.13ba7f19923da2c078ef.pdf";
import RevisedDatesheetofBPharm8thSemProjectVivaVoceExaminationJuly2023SPS from "./Datesheet Data/Revised-Datesheet-of-B.Pharm-8th-Sem-Project-Viva-Voce-Examination-July-2023-SPS.pdf";
import RescheduledDateSheetofDPharm1stYearAnnualSupplExaminationJuly20231 from "./Datesheet Data/Rescheduled-Date-Sheet-of-D.Pharm_.-1st-Year-Annual-Suppl.-Examination-July-2023_1.pdf";
import RescheduledDateSheetofDPharm2ndYearPracticalJuly2023 from "./Datesheet Data/Rescheduled-Date-Sheet-of-D.Pharm_.-2nd-Year-Practical-July-2023.pdf";
import RESHEDULEDDATESHEETOFBPHARM8THSEMPROJECTVIVAVOCEEXAMDIPSARJULY2023 from "./Datesheet Data/RESHEDULED-DATESHEET-OF-B.PHARM-8TH-SEM-PROJECT-VIVA-VOCE-EXAM.-DIPSAR-JULY-2023.pdf";
import DATESHEETOFBPHARM8THSEMESTERPROJECTVIVAVOCEEXAMINATIONSPSJULY2023 from "./Datesheet Data/DATESHEET-OF-B.PHARM-8TH-SEMESTER-PROJECT-VIVA-VOCE-EXAMINATION-SPS-JULY-2023.pdf";
import DATESHEETOFBPHARM8THSEMESTERPROJECTVIVAVOCEDIPSARJULY2023 from "./Datesheet Data/DATESHEET-OF-B.PHARM-8TH-SEMESTER-PROJECT-VIVA-VOCE-DIPSAR-JULY-2023.pdf";
import DatesheetofDPharm2ndyearPracticalOldSchemeExaminationJuly2023 from "./Datesheet Data/Date-sheet-of-D.Pharm_.-2nd-year-Practical-Old-Scheme-Examination-July-2023.pdf";
import DateSheetofDpharm2ndyearPracticalExaminationJuly2023 from "./Datesheet Data/Date-Sheet-of-D.pharm_.-2nd-year-Practical-Examination-July-2023.pdf";
import DateSheetofBPT6thSemesterTheoryExamiantionJuly2023 from "./Datesheet Data/Date-Sheet-of-BPT-6th-Semester-Theory-Examiantion-July-2023.pdf";
import DateSheetofBPT8thSemesterTheoryExamiantionJuly2023 from "./Datesheet Data/Date-Sheet-of-BPT-8th-Semester-Theory-Examiantion-July-2023.pdf";
import DateSheetofBScHonsSportsScience6thSemesterTheoryExaminationJuly2023 from "./Datesheet Data/Date-Sheet-of-B.-Sc.-Hons.-Sports-Science-6th-Semester-Theory-Examination-July-2023.pdf";
import DatesheetofDpharm1stYearAnnualSupplementaryExaminationJuly2023 from "./Datesheet Data/Date-sheet-of-D.pharm_.-1st-Year-Annual-Supplementary-Examination-July-2023.pdf";
import DatesheetofBPharm8thSemesterTheoryExaminationJune2023 from "./Datesheet Data/Datesheet-of-B.Pharm-8th-Semester-Theory-Examination-June-2023.pdf";
import DateSheetofDPharm2ndYearAnnualSupplementaryExaminationJune20231 from "./Datesheet Data/Date-Sheet-of-D.Pharm_.-2nd-Year-Annual-Supplementary-Examination-June-2023_1.pdf";
import DatesheetofMScSportsScience1st3rdSemesterPracticalExaminationMay202311 from "./Datesheet Data/Date-sheet-of-M.Sc_.-Sports-Science-1st-3rd-Semester-Practical-Examination-May-2023_1-1.pdf";
import PracticalDatesheetofMPharm1stSemesterPracticalExaminationDRAExeDRSBranchSPSMay2023 from "./Datesheet Data/Practical-Datesheet-of-M.Pharm-1st-Semester-Practical-Examination-DRA-Exe-DRS-Branch-SPS-May-2023.pdf";
import PracticalDatesheetofBPharm2ndSemesterSupplePracticalExaminationDIPSARMay2023 from "./Datesheet Data/Practical-Datesheet-of-B.Pharm-2nd-Semester-Supple.-Practical-Examination-DIPSAR-May-2023.pdf";
import DateSheetofBScHons1stSemesterPracticalExaminationMay2023 from "./Datesheet Data/Date-Sheet-of-B.-Sc.-Hons.-1st-Semester-Practical-Examination-May-2023.pdf";
import DatesheetofMPharm1stSemesterPracticalExaminationSPSMay20231 from "./Datesheet Data/Datesheet-of-M.Pharm-1st-Semester-Practical-Examination-SPS-May-2023-1.pdf";
import DateSheetBPharmAyurveda1stSemesterpracticalExamination1 from "./Datesheet Data/Date-Sheet-B.Pharm-Ayurveda-1st-Semester-practical-Examination-1.pdf";
import DateSheetBPharmAyurveda3rdSemesterPracticalExam from "./Datesheet Data/Date-Sheet-B.Pharm-Ayurveda-3rd-Semester-Practical-Exam.pdf";
import DateSheetofBPT4th6th8thSemesterSupplementaryPracticalExaminationMay2023 from "./Datesheet Data/Date-Sheet-of-BPT-4th-6th-8th-Semester-Supplementary-Practical-Examination-May-2023.pdf";
import DateSheetofMScAIM1stSemesterPracticalExaminationMay2023 from "./Datesheet Data/Date-Sheet-of-M.Sc_.-AIM-1st-Semester-Practical-Examination-May-2023.pdf";
import DateSheetofMScAIPM3rdSemesterPracticalExaminationMay2023 from "./Datesheet Data/Date-Sheet-of-M.Sc_.-AIPM-3rd-Semester-Practical-Examination-May-2023.pdf";
import DatesheetofMPharm1stSemesterPracticalExaminationDIPSARMay2023 from "./Datesheet Data/Datesheet-of-M.Pharm-1st-Semester-Practical-Examination-DIPSAR-May-2023.pdf";
import DatesheetofBPharm6thSemesterTheorySuppleExaminationDIPSARDPSRUApril2023 from "./Datesheet Data/Datesheet-of-B.Pharm-6th-Semester-Theory-Supple.-Examination-DIPSAR-DPSRU-April-2023.pdf";
import DatesheetofBPharm4thSemesterTheorySuppleExaminationDIPSARDPSRUApril2023 from "./Datesheet Data/Datesheet-of-B.Pharm-4th-Semester-Theory-Supple.-Examination-DIPSAR-DPSRU-April-2023.pdf";
import DatesheetofBPharm2ndSemesterTheorySuppleExaminationDIPSARDPSRUApril2023 from "./Datesheet Data/Datesheet-of-B.Pharm-2nd-Semester-Theory-Supple.-Examination-DIPSAR-DPSRU-April-2023.pdf";
import DatesheetofMPharm1stSemesterTheoryExaminationDRAExeDRSSPSApril2023 from "./Datesheet Data/Datesheet-of-M.Pharm-1st-Semester-Theory-Examination-DRA-Exe.-DRS-SPS-April-2023.pdf";
import DatesheetofBPharm3rdSemesterPracticalExaminationSPSApril2023 from "./Datesheet Data/Datesheet-of-B.Pharm-3rd-Semester-Practical-Examination-SPS-April-2023.pdf";
import DatesheetofBPT2nd4thsemestersuppleTheoryExaminationApril2023 from "./Datesheet Data/Date-sheet-of-BPT-2nd-4th-semester-supple.-Theory-Examination-April-2023.pdf";
import DatesheetofBPT6thsemestersuppleTheoryExaminationApril2023 from "./Datesheet Data/Date-sheet-of-BPT-6th-semester-supple.-Theory-Examination-April-2023.pdf";
import DatesheetofMPharm3rdSemesterExaminationDIPSARApril2023 from "./Datesheet Data/Datesheet-of-M.Pharm-3rd-Semester-Examination-DIPSAR-April-2023.pdf";
import DatesheetofBPharm3rdSemesterExaminationDIPSARApril2023 from "./Datesheet Data/Datesheet-of-B.Pharm-3rd-Semester-Examination-DIPSAR-April-2023.pdf";
import DateSheetofDMLTBMLT1stSemesterPracticalexaminationApril20231 from "./Datesheet Data/Date-Sheet-of-DMLT-BMLT-1st-Semester-Practical-examination-April-2023_1.pdf";
import DateSheetofMScAIPM3rdSemesterTheoryExaminationApril2023 from "./Datesheet Data/Date-Sheet-of-M.Sc_.-AIPM-3rd-Semester-Theory-Examination-April-2023.pdf";
import DateSheetofMScAIM1stSemesterTheoryExaminationMay2023 from "./Datesheet Data/Date-Sheet-of-M.Sc_.-AIM-1st-Semester-Theory-Examination-May-2023.pdf";
import DateSheetofMPT1stSemesterPracticalExaminationApril2023 from "./Datesheet Data/Date-Sheet-of-MPT-1st-Semester-Practical-Examination-April-2023.pdf";
import DateSheetofMPTSportsMedicine3rdSemesterPracticalExaminationApril2023 from "./Datesheet Data/Date-Sheet-of-MPT-Sports-Medicine-3rd-Semester-Practical-Examination-April-2023.pdf";
import DateSheetofBScHonsSportsScience1stSemesterTheoryExaminationApril2023 from "./Datesheet Data/Date-Sheet-of-B.-Sc.-Hons.-Sports-Science-1st-Semester-Theory-Examination-April-2023.pdf";
import DateSheetofMScSportsScience1st3rdSemesterExaminationApril202 from "./Datesheet Data/Date-Sheet-of-M.-Sc.-Sports-Science-1st-3rd-Semester-Examination-April-2023.pdf";
import DateSheetofBPharmayurveda1stSemTheoryExaminationApril2023 from "./Datesheet Data/DateSheet-of-B.Pharm-ayurveda-1st-Sem-Theory-ExaminationApril-2023.pdf";
import DateSheetofBPharmayurveda3stSemTheoryExaminationApril2023 from "./Datesheet Data/DateSheet-of-B.Pharm-Ayurveda-3rd-Sem-Theory-Exam-April-2023.pdf";
import DatesheetofBPharm8thSemesterTheorySupplementaryExaminationSPSAPRIL2023 from "./Datesheet Data/Datesheet-of-B.Pharm-8th-Semester-Theory-Supplementary-Examination-SPS-APRIL-2023.pdf";
import DatesheetofMPharm1stSemesterTheoryExaminationSPSApril20232 from "./Datesheet Data/Datesheet-of-M.Pharm-1st-Semester-Theory-Examination-SPS-April-20232.pdf";
import DatesheetofMPharm1stSemesterTheoryExaminationDIPSARApril2023 from "./Datesheet Data/Datesheet-of-M.Pharm-1st-Semester-Theory-Examination-DIPSAR-April-2023.pdf";
import DateSheetofBPT1st3rdSemesterPracticalExaminationApril2023 from "./Datesheet Data/Date-Sheet-of-BPT-1st-3rd-Semester-Practical-Examination-April-2023.pdf";
import PracticalDateSheetofBScBiomedical1stSemesterApril2023 from "./Datesheet Data/Practical-DateSheet-of-B.Sc_.-Biomedical-1st-Semester-April-2023.pdf";
import PracticalDatesheetofBPharm5thSemesterExaminationSPSMarch2023 from "./Datesheet Data/Practical-Datesheet-of-B.Pharm-5th-Semester-Examination-SPS-March-2023.pdf";
import MBAPET1stSemesterSupplementarytheoryExamMarch2023 from "./Datesheet Data/MBA-PET-1st-Semester-Supplementary-theory-Exam-March-2023.pdf";
import RevisedDatesheetofBPharm3rdSemesterTheoryExaminationApril2023 from "./Datesheet Data/Revised-Date-sheet-of-B.-Pharm-3rd-Semester-Theory-Examination-April-2023.pdf";
import DateSheetofMPTSM3rdSemesterExaminationMarch2023 from "./Datesheet Data/Date-Sheet-of-MPT-SM-3rd-Semester-Examination-March-2023.pdf";
import DateSheetofMPT1stSemesterExaminationMarch2023 from "./Datesheet Data/Date-Sheet-of-MPT-1st-Semester-Examination-March-2023.pdf";
import DateSheetofDMLTBMLT1stSemApril2023 from "./Datesheet Data/Date-Sheet-of-DMLT-BMLT-1st-Sem.-April-2023.pdf";
import DateSheetofDMLTBMLT1stSemSupplementaryApril2023 from "./Datesheet Data/Date-Sheet-of-DMLT-BMLT-1st-Sem.-Supplementary-April-2023.pdf";
import CamScanner0317202313031 from "./Datesheet Data/CamScanner-03-17-2023-13.03_1.pdf";
import DateSheetofMBAPMITMHHMMPH1stSemTheoryExaminations from "./Datesheet Data/Date-Sheet-of-MBAPM-ITM-HHM-MPH-1st-Sem-Theory-Examinations.pdf";
import DateSheetofBBAHC1stSemesterexaminationMarch2023 from "./Datesheet Data/Date-Sheet-of-BBA-HC-1st-Semester-examination-March-2023.pdf";
import DateSheetofBBACH1stSemesterSupplementaryexaminationMarch2023new from "./Datesheet Data/Date-Sheet-of-BBA-CH-1st-Semester-Supplementary-examination-March-2023new.pdf";
import PracticalDatesheetBMLT3rdSemesterMarch2023 from "./Datesheet Data/Practical-Date-sheet-BMLT-3rd-Semester-March-2023.pdf";
import practicalDatesheetDMLT3rdSemesterMarch2023 from "./Datesheet Data/practical-Date-sheet-DMLT-3rd-Semester-March-2023.pdf";
import DateSheetofBPT3rdSemesterTheoryExaminationMarhc2023 from "./Datesheet Data/Date-Sheet-of-BPT-3rd-Semester-Theory-Examination-Marhc-2023.pdf";
import DateSheetofBPT1stSemesterTheoryExaminationMarch20231 from "./Datesheet Data/Date-Sheet-of-BPT-1st-Semester-Theory-Examination-March-2023-1.pdf";
import DatesheetofBScHonsSportsScience3rdSemesterpracticalexaminationMarch202311 from "./Datesheet Data/Date-sheet-of-B.Sc_.-Hons.-Sports-Science-3rd-Semester-practical-examination-March-2023_1-1.pdf";
import DateSheetofPrePhDPracticalExaminationsMarch20231 from "./Datesheet Data/Date-Sheet-of-Pre-PhD-Practical-Examinations-March-2023_1.pdf";
import AdobeScan13Mar2023 from "./Datesheet Data/Adobe-Scan-13-Mar-2023.pdf";
import BMLTDMLT3rdSEMTheoryExamDateSheet from "./Datesheet Data/BMLT-DMLT-3rd-SEM-Theory-Exam-Date-Sheet.pdf";
import BBA3rdSemTheoryxamDateSheet from "./Datesheet Data/BBA-3rd-Sem-Theory-Exam-Date-Sheet.pdf";
import DatesheetofBPharm1stSemesterTheoryExaminationMarch2023 from "./Datesheet Data/Datesheet-of-B.Pharm-1st-Semester-Theory-Examination-March-2023.pdf";
import DatesheetofBPharm5thSemesterTheoryExaminationMarch2023 from "./Datesheet Data/Datesheet-of-B.Pharm-5th-Semester-Theory-Examination-March-2023.pdf";
import BPharm7thSemesterPracticalDatesheetSPSFebruary2023 from "./Datesheet Data/B.Pharm-7th-Semester-Practical-Datesheet-SPS-February-2023.pdf";
import BPharm7thSemesterPracticalDatesheetDIPSARFebruary2023 from "./Datesheet Data/B.Pharm-7th-Semester-Practical-Datesheet-DIPSAR-February-2023.pdf";
import DateSheetofBScHonsSportsSciences5thSemesterPracticalExaminationFeb2023 from "./Datesheet Data/Date-Sheet-of-B.Sc_.-Hons.-Sports-Sciences-5th-Semester-Practical-Examination-Feb-2023.pdf";
import DatesheetofBPharm5thSemesterSupplePracticalExaminationSPSFebruary2023 from "./Datesheet Data/Datesheet-of-B.Pharm-5th-Semester-Supple.-Practical-Examination-SPS-February-2023.pdf";
import RevisedDatesheetofBPharm7thSemesterTheoryExaminationFebruary2023 from "./Datesheet Data/Revised-Datesheet-of-B.Pharm-7th-Semester-Theory-Examination-February-2023.pdf";
import DateSheetofPrePhDexaminationFeb20231 from "./Datesheet Data/Date-Sheet-of-Pre-PhD-examination-Feb-2023_1.pdf";
import DateSheetofDPharm1styearpracticalexaminationsupplementaryFeb2023 from "./Datesheet Data/Date-Sheet-of-D.Pharm-1st-year-practical-examination-supplementary-Feb-2023.pdf";
import DatesheetofBPharm1stSemesterSupplePracticalExaminationFebruary2023 from "./Datesheet Data/Datesheet-of-B.Pharm-1st-Semester-Supple.-Practical-Examination-February-2023.pdf";
import DateSheetofBPT5th7thSemesterPracticalExaminationFebruary2023 from "./Datesheet Data/Date-Sheet-of-BPT-5th-7th-Semester-Practical-Examination-February-2023.pdf";
import DateSheetofBScHonsSportsScience3rdSemesterTheoryExaminationFeb2023 from "./Datesheet Data/Date-Sheet-of-B.-Sc.-Hons.-Sports-Science-3rd-Semester-Theory-Examination-Feb-2023.pdf";
import DateSheetofBScHonsSportsScience5thSemesterTheoryExaminationFeb20231 from "./Datesheet Data/Date-Sheet-of-B.-Sc.-Hons.-Sports-Science-5th-Semester-Theory-Examination-Feb-2023-1.pdf";
import DateSheetofSAYAMMCWCSC2ndSemesterPracticalExaminationFeb20231 from "./Datesheet Data/Date-Sheet-of-SAYA-MMC-WCSC-2nd-Semester-Practical-Examination-Feb-2023-1.pdf";
import RevisedDatesheetofBPharm5thSemesterSupplementaryTheoryExaminationJanuary2023 from "./Datesheet Data/Revised-Datesheet-of-B.-Pharm.-5th-Semester-Supplementary-Theory-Examination-January-2023..pdf";
import DateSheetofBPharm1stSemesterSuppleExaminationJanuary2023 from "./Datesheet Data/Date-Sheet-of-B.Pharm-1st-Semester-Supple.-Examination-January-2023.pdf";
import DateSheetofBPharm3rdSemesterSuppleExaminationJanuary2023 from "./Datesheet Data/Date-Sheet-of-B.Pharm-3rd-Semester-Supple.-Examination-January-2023.pdf";
import DateSheetofBPT7thSemester from "./Datesheet Data/Date-Sheet-of-BPT-7th-Semester.pdf";
import DateSheetofBPT5thSemester from "./Datesheet Data/Date-Sheet-of-BPT-5th-Semester.pdf";
import DateSheetofBPT3rdsemestersupplementaryExamsJan2023 from "./Datesheet Data/Date-Sheet-of-BPT-3rd-semester-supplementary-Exams-Jan-2023.pdf";
import DateSheetofBPT1stsemestersupplementaryExamsJan20231 from "./Datesheet Data/Date-Sheet-of-BPT-1st-semester-supplementary-Exams-Jan-2023-1.pdf";
import DateSheetofWCSC2ndsemesterExamsJan2023 from "./Datesheet Data/Date-Sheet-of-WCSC-2nd-semester-Exams-Jan-2023.pdf";
import DateSheetofMPharm2ndSemesterPracticalExaminationDIPSARNovember2022 from "./Datesheet Data/Date-Sheet-of-M.Pharm-2nd-Semester-Practical-Examination-DIPSAR-November-2022.pdf";
import DateSheetofMScSportsScience2ndSemesterPracticalExaminationNovember2022 from "./Datesheet Data/Date-Sheet-of-M.-Sc.-Sports-Science-2nd-Semester-Practical-Examination-November-2022.pdf";
import DateSheetofMPharm2ndSemesterDRAPracticalExaminationSPSNovember2022 from "./Datesheet Data/Date-Sheet-of-M.Pharm-2nd-Semester-DRA-Practical-Examination-SPS-November-2022.pdf";
import DatesheetofMPharm2ndSemesterPracticalExaminationExecutiveDrugRegulatoryScienceSPSNovember2022 from "./Datesheet Data/Datesheet-of-M.-Pharm-2nd-Semester-Practical-Examination-Executive-Drug-Regulatory-Science-SPS-November-2022..pdf";
import DateSheetofBPharm2ndSemesterPracticalExaminationSPSOctober2022 from "./Datesheet Data/Date-Sheet-of-B.-Pharm-2nd-Semester-Practical-Examination-SPS-October-2022..pdf";
import DateSheetofBPharm2ndSemesterPracticalExaminationDIPSAROctober2022 from "./Datesheet Data/Date-Sheet-of-B.Pharm-2nd-Semester-Practical-Examination-DIPSAR-October-2022.pdf";
import DateSheetofMPTSportsMedicine2ndsemesterpracticalExaminationNovember2022 from "./Datesheet Data/Date-Sheet-of-MPT-Sports-Medicine-2nd-semester-practical-Examination-November-2022.pdf";
import DateSheetofBScHonsSportsScience2ndsemesterPracticalExaminationOctober2022 from "./Datesheet Data/Date-Sheet-of-B.-Sc.-Hons.-Sports-Science-2nd-semester-Practical-Examination-October-2022.pdf";
import DateSheetofMScSportsScience2ndSemesterTheoryExaminationOctober2022 from "./Datesheet Data/Date-Sheet-of-M.-Sc.-Sports-Science-2nd-Semester-Theory-Examination-October-2022.pdf";
import DateSheetofBPharm4thSemesterTheoryExaminationforLateralEntryStudentsofDIPSARSPSOctober2022 from "./Datesheet Data/Date-Sheet-of-B.Pharm-4th-Semester-Theory-Examination-for-Lateral-Entry-Students-of-DIPSAR-SPS-October-2022.pdf";
import DateSheetofBScHSportsSciences4thSemesterSupplementaryPracticalExaminationSeptember2022 from "./Datesheet Data/Date-Sheet-of-B.-Sc.-H.-Sports-Sciences-4th-Semester-Supplementary-Practical-Examination-September-2022.pdf";
import DateSheetofDPharm2ndYearSupplementaryPracticalExaminationAugust2022 from "./Datesheet Data/Date-Sheet-of-D.-Pharm-2nd-Year-Supplementary-Practical-Examination-August-2022.pdf";
import RevisedDateSheetofBPharm7thSemesterSupplePracticalExamAugust2022 from "./Datesheet Data/Revised-Date-Sheet-of-B.Pharm-7th-Semester-Supple.-Practical-Exam-August-2022.pdf";
import RevisedDateSheetofBPharm7thSemesterSuppleTheoryExaminationAugust2022 from "./Datesheet Data/Revised-Date-Sheet-of-B.Pharm-7th-Semester-Supple.-Theory-Examination-August-2022.pdf";
import DatesheetofDPharm2ndYearSupplementaryExaminationAugust2022 from "./Datesheet Data/Date-sheet-of-D.-Pharm-2nd-Year-Supplementary-Examination-August-2022.pdf";

// newdatesheet on 14/10/23
import dateSheetNewData001 from "./Datesheet Data/Date-Sheet-of-BPT-2nd-4th-Semester-Practical-Examination-Oct-2023.pdf";
import dateSheetNewData002 from "./Datesheet Data/Date-sheet-of-Pre-Ph.D-Practical-Supplementary-examination-October-2023.pdf";
import dateSheetNewData003 from "./Datesheet Data/Date-sheet-pre-phd-supplementary-Examination-October-2023.pdf";
import dateSheetNewData004 from "./Datesheet Data/Date-Sheet-of-MPT-Sports-Medicine-Cardiovascular-and-Pulmonary-Pediatrics-2nd-Semester-Practical-Examinations-Oct-2023.pdf";
import dateSheetNewData005 from "./Datesheet Data/Date-Sheet-of-MPTS-4th-Semester-Practical-Examinations-Oct-2023.pdf";
import dateSheetNewData006 from "./Datesheet Data/Datesheet-of-M.Pharm-2nd-Semester-Theory-Examination-SPS-October-2023-1.pdf";
import dateSheetNewData007 from "./Datesheet Data/Datesheet-of-M.Pharm-2nd-Semester-Theory-Examination-DIPSAR-October-2023-1.pdf";
import dateSheetNewData008 from "./Datesheet Data/Date-Sheet-of-B.Sc_.-Hons.-Sports-Science-2nd-semester-practical-examinations-October-2023.pdf";
import dateSheetNewData009 from "./Datesheet Data/Date-Sheet-of-B.Pharm-Ayurveda-4th-Semester-Examination-October-2023.pdf";
import dateSheetNewData010 from "./Datesheet Data/Date-Sheet-of-B.Pharm-Ayurveda-2nd-Semester-Examination-October-2023.pdf";
import dateSheetNewData011 from "./Datesheet Data/Practical-Date-Sheet-of-M.Sc_.-AIM-2nd-Semester-Examination-October-2023.pdf";
import dateSheetNewData012 from "./Datesheet Data/Practical-Date-Sheet-of-MBA-PM-MBA-HHM-MPH-2nd-Semester-Examination-October-2023.pdf";
import dateSheetNewData013 from "./Datesheet Data/Date-Sheet-of-BPT-2nd-4th-Semester-Examinations-Oct-2023.pdf";
import dateSheetNewData014 from "./Datesheet Data/Datesheet-of-B.Pharm-2nd-Semester-Practical-Examination-SPS-September-2023.pdf";
import dateSheetNewData015 from "./Datesheet Data/Datesheet-of-B.Pharm-4th-Semester-Practical-Examination-SPS-September-2023.pdf";
import dateSheetNewData016 from "./Datesheet Data/Datesheet-of-B.Pharm-2nd-Semester-Practical-Examination-DIPSAR-September-2023.pdf";
import dateSheetNewData017 from "./Datesheet Data/Datesheet-of-B.Pharm-4th-Semester-Practical-Examination-DIPSAR-September-2023.pdf";
import dateSheetNewData018 from "./Datesheet Data/Date-Sheet-of-MPT-Sports-Medicine-Cardiovascular-and-Pulmonary-Pediatrics-2nd-Semester-Theory-Examination-September-2023.pdf";
import dateSheetNewData019 from "./Datesheet Data/Date-Sheet-of-DMLT-BMLT-4th-Semester-Practical-Examination-September-2023.pdf";
import dateSheetNewData020 from "./Datesheet Data/Date-Sheet-of-DMLT-BMLT-2nd-Semester-Main-and-Supplementary-Practical-Examination-September-2023.pdf";

// new data after live website
import newdata0001 from "./Datesheet Data/Datesheet of M.Pharm 2nd Semester Practical Examination (SPS), October 2023.pdf";
import newdata0002 from "./Datesheet Data/Revised Date sheet of DMYS 2nd & 3rd Trimester (Batch-I & II) Supple. Examination, October -2023.pdf";
import newdata0003 from "./Datesheet Data/Kaagaz_20231016_184334818344.pdf";
import newdata0004 from "./Datesheet Data/Datesheet of M.pharm 2nd sem Practical Exams DIPSAR, October 2023.pdf";
import newdata0005 from "./Datesheet Data/Date Sheet of M. Sc. Sports Science 2nd & 4th Semester Practical Examinations, Oct-2023.pdf";
import newdata0006 from "./Datesheet Data/Practical Date Sheet of B.Pharm (Ayurveda) 2nd Semester Examination, October-2023.pdf";
import newdata0007 from "./Datesheet Data/Practical Date Sheet of B.Pharm (Ayurveda) 4th Semester Examination, October-2023.pdf";
import newdata0008 from "./Datesheet Data/Datesheet of M.pharm 2nd sem Practical Examination DIPSAR, October 2023.pdf";
import newdata0009 from "./Datesheet Data/Date sheet of DMLT 4th Semester (Internship Report Evaluation) Examination, October -2023.pdf";
import datesheetofmbapm3rdsemexamdec2023 from "./Datesheet Data/DateSheet of MBA-PM 3rd Semester Supplementary Examination, Dec-2023.pdf";
import datesheetDpharm1styeardec2023 from "./Datesheet Data/Date sheet of D.Pharm. 1st year Supplementary Examination, December-2023.pdf";
import datesheetbpt7thsemdec2023 from "./Datesheet Data/Date Sheet of BPT 7th Semester Supplementary Theory Examination, Dec-2023.PDF";
import dsdpharm2ndsemdec2023 from "./Datesheet Data/Date sheet of D.Pharm. 2nd year Supplementary Examination, December-2023.pdf";
import dsofsayawcsc1nd2ndsem from "./Datesheet Data/Date Sheet of  SAYA (WCSC) 1st & 2nd Semester Supplementary Theory Examinations, Dec-2023.PDF";
import dsofmmcwcscthoexam from "./Datesheet Data/Date Sheet of MMC (WCSC) 1st & 2nd Semester Supplementary Theory Examinations, Dec-2023.PDF";
import dpharm1stand2ndsuppexamdec2023 from "./Datesheet Data/Date sheet of D.Pharm. 1st & 2nd Year Supplementary Examination December -2023.pdf";
import medicinemanagementwcsc1styearand2ndsem from "./Datesheet Data/Date Sheet of Medicine Management (WCSC) 1st and 2nd Semester Supplementary Practical Examination, January-2024.pdf";
import sprotsandyogawcsc1stand2ndsemsupppracticalexaminationjan from "./Datesheet Data/Date Sheet of Sports Assessment & Yoga Application (WCSC) 1st and 2nd Semester Supplementary Practical Examination, January-2024.pdf";
import bpharm1stand5thsemtheoryexam from "./Datesheet Data/Datesheet of B.Pharm 1st & 5th Semester Theory Examination (DIPSAR & SPS), January 2024.pdf";
import bharm3rdand7thsemtheoex from "./Datesheet Data/Datesheet of B.Pharm 3rd & 7th Semester Theory Examination (DIPSAR & SPS), January 2024.pdf";
import bpt1stand7thsemtheoryexamfeb23 from "./Datesheet Data/Date Sheet of BPT 1st & 7th Semester Theory Examinations, Feb-2023.pdf";
import dmlt1stsemexamfeb24 from "./Datesheet Data/Date sheet of DMLT 1st Semester Examination, February -2024.pdf";
import bbahc1stsemexamfeb24 from "./Datesheet Data/Date sheet of BBA-HC 1st Semester Examination, February -2024.pdf";
import bmlt1stsemexamfeb24 from "./Datesheet Data/Date sheet of BMLT 1st Semester Examination, February -2024.pdf";
import bmlt5stsemexamfeb24 from "./Datesheet Data/Date sheet of BMLT 5th Semester Examination, February -2024.pdf";
import bbahc3rdsemfeb24 from "./Datesheet Data/Date sheet of BBA-HC 3rd Semester Examination, February -2024.pdf";
import bbahc5rdsemfeb24 from "./Datesheet Data/Date sheet of BBA-CH 5th Semester Examination, February -2024.pdf";
import dmlt3rdsemexamfeb24 from "./Datesheet Data/Date sheet of DMLT 3rd Semester Examination, February -2024.pdf";
import bmlt3rdsemexamfeb24 from "./Datesheet Data/Date sheet of BMLT 3rd Semester Examination, February -2024.pdf";
import bbach3rdsemsuppexamfeb24 from "./Datesheet Data/Date sheet of BBA-CH 1st and 3rd Semester Supplementary Examination, February -2024.pdf";
import bschonssprotscience5thsemthoexamfeb24 from "./Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 5th Semester Theory Examinations, Feb-2024.pdf";
import mptsportmediciecadiandpi1stsem from "./Datesheet Data/Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 1st Semester Theory Examinations, Feb-2024.pdf";
import bscss1stsemthoexamfeb24 from "./Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 1st Semester Theory Examinations, Feb-2024.pdf";
import BschonsbiomedicalScience1stsemthoryexamfeb24 from "./Datesheet Data/Date Sheet of B.Sc. (Hons.) Biomedical Sciences 1st Semester Theory Examination, February-2024.pdf";
import Bpharmayrveda1stsemthorexamfeb24 from "./Datesheet Data/Date Sheet of B.Pharm (Ayurveda) 1st Semester Theory Examination, February -2024 (1).pdf";
import bscbiomedicalscience3rdsemthoexamfeb24 from "./Datesheet Data/Date Sheet of B.Sc. (Hons.) Biomedical Sciences 3rd Semester Theory Examination, February-2024.pdf";
import bschonsBiomediacalsciences1stsemsuppexamfeb24 from "./Datesheet Data/Date Sheet of B.Sc. (Hons.) Biomedical Sciences 1st Semester Supple. Examination, February-2024.pdf";
import Bpharm3rdsempracticaldatesheetSPSfeb24 from "./Datesheet Data/B.Pharm 3rd Semester Practical Datesheet (SPS), February 2024.pdf";
// import Bpharm1stsempracticaldatesheetSPSfeb24 from './Datesheet Data/B.Pharm 1st Semester Practical Datesheet (SPS), February 2024.pdf'
import Bpharm1stsempracticaldatesheetSPSfeb24 from "./Datesheet Data/B.Pharm 1st Semester Practical Datesheet (SPS), February 2024(1).pdf";
import Bpharm7thsempracticaldatesheetSPSfeb24 from "./Datesheet Data/B.Pharm 7th Semester Practical Datesheet (SPS), February 2024.pdf";
import Bpharm5thsempracticaldatesheetSPSfeb24 from "./Datesheet Data/B.Pharm 5th Semester Practical Datesheet (SPS), February 2024.pdf";
import bpharm1stsemparticaldatesheetDipsarfeb24 from "./Datesheet Data/B.Pharm 1st Semester Practical Datesheet (DIPSAR), February 2024.pdf";
import Bpharm3rdsempraticalDatesheetDipsarfeb24 from "./Datesheet Data/B.Pharm 3rd Semester Practical Datesheet (DIPSAR), February 2024.pdf";
import Bpharm5thsemPracticalDatesheetDipsarFeb24 from "./Datesheet Data/B.Pharm 5th Semester Practical Datesheet (DIPSAR), February 2024.pdf";
import Bpharm7thsemPracticalDatesheetDipsarfeb24 from "./Datesheet Data/B.Pharm 7th Semester Practical Datesheet (DIPSAR), February 2024 .pdf";
import corigendrumDatesheetBPharm3rdsempraExam from "./Datesheet Data/Corrigendum Datesheet of B.Pharm 3rd Sem. Practical Exam. DIPSAR, February 2024.pdf";
import mbahhmmph3rdsemtheexfeb24 from "./Datesheet Data/Date Sheet of MBA-HHM & MPH 3rd Semester Theory Examination, February-2024.pdf";
import mbaitmmbapm3rdsemthoexamfeb24 from "./Datesheet Data/Date Sheet of MBA ITM & MBA PM 3rd  Semester Theory Examination, February-2024.pdf";
import mbaitmmbapm1stsemthoexamfeb24 from "./Datesheet Data/Date Sheet of MBA(PM, ITM & HHM) & MPH 1st Semester Theory Examination, February-2024.pdf";
import datesheetofmptsprotmedicinecadiolpr1stsemfeb24 from "./Datesheet Data/Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 1st Semester Practical Examinations, Feb-2024.pdf";
import datesheetofapbt7thsempracticaexamfeb24 from './Datesheet Data/Date Sheet of BPT 7th Semester Practical Examinations, Feb-2024.pdf'
import datesheetpfpbt1stsempracexam24 from './Datesheet Data/Date Sheet of BPT 1st Semester Practical Examinations, Feb-2024.pdf'
import datesheetOfBschonssportsSciecce5thsempracticalexam24 from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 5th Semester Practical Examinations, Feb-2024.pdf'
import datesheetofbscBiomedicalscience1stsemprac from './Datesheet Data/Date sheet of B.Sc. (Biomedical Sciences) 1st Semester Practical Examination, Feb-2024.pdf'
import dsofbmlt5thsempractiocalexamfeb24 from './Datesheet Data/Date sheet of BMLT 5th Semester (Practical) Examination, February-2024.pdf'
import dsofdmlt1stsempracticalexamfeb24 from './Datesheet Data/Date sheet of DMLT 1st Semester (Practical) Examination, February-2024.pdf'
import dsofbmlt1stsempracticalexamfeb24 from './Datesheet Data/Date sheet of BMLT 1st Semester (Practical) Examination, February-2024.pdf'
import datesheetofDMLT3rdsempracticalexamfeb24 from './Datesheet Data/Date sheet of DMLT 3rd Semester (Practical) Examination, February-2024.pdf'
import datesheetOfBMLT3rdsempracexamfeb24 from './Datesheet Data/Date sheet of BMLT 3rd Semester (Practical) Examination, February-2024.pdf'
import dsofbschons1stsempractexamfeb24 from './Datesheet Data/Date sheet of B.Sc. (Hons.) Sports Science 1st semester (Practical) Examination, March -2024.pdf'
import dsofBscBio3rdsempractexamfeb24 from './Datesheet Data/Date sheet of B.Sc. Biomedical 3rd Semester (Practical) Examination, February-2024.pdf'
import dsofbscHonssportsScience3rdsemthoexammar24 from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 3rd Semester Theory Examinations, March-2024.pdf'
import dsofMscHonssportsScience3rdsemthoexammar24 from './Datesheet Data/Date Sheet of M. Sc. Sports Science 3rd Semester Theory Examinations, March-2024.pdf'
import dsofbpharmayurveda1stsempracticalexamspsfeb24 from './Datesheet Data/Datesheet of B.Pharm Ayurveda 1st Semester Practical Examination SPS, February 2024 .pdf'
import datesheetofccyPracticalExaminationmarch2024 from './Datesheet Data/Date sheet of CCY (Practical) Examination, March-2024.pdf'
import datesheetofccyTheoryExaminationmarch2024 from './Datesheet Data/Date sheet of CCY (Theory) Examination, March-2024.pdf'
import BPT3rdand5thsemthoexammar24 from './Datesheet Data/Date Sheet of BPT 3rd & 5th Semester Theory Examinations, March-2024.pdf'
import dataSheetOfMptSportMedicineCardiovascularAndpul3rdthoexam from './Datesheet Data/Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 3rd Semester Theory Examinations.pdf'
import revisedDatesheetofBptrdSemand5thsemtheoryexam24 from './Datesheet Data/Revised Date sheet of BPT 3rd & 5th Semester Theory Examination, March-2024.pdf'
import datesheetofmscsportsscience3rdsempraexammar24 from './Datesheet Data/Date Sheet of M. Sc. Sports Science 3rd Semester Practical Examinations, March-2024.pdf'
import dsofbschonssprotscience3rdsempracexamnar24 from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 3rd Semester Practical Examinations, March-2024.pdf'
import mptsportsMedicineCardiovascularandpul3rdsempracexammar24 from './Datesheet Data/Date Sheet of MPT(Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 3rd Semester Practical Examinations, March-2024.pdf'
import datesheetofBpharmAyruveda3rdand5thsenthoexamapr24 from './Datesheet Data/Datesheet of B.Pharm  Ayurveda 3rd & 5th Semester Theory Examination, April 2024.pdf'
import bpt3rdand5thsempracticalexamapr24 from './Datesheet Data/Date sheet of BPT 3rd and 5th Semester Practical Examination, April -2024.pdf'
import mscAimand3rdsemtheoexamApr24 from './Datesheet Data/Date of M. Sc. AIM 1st and 3rd Semester Theory Examination, April -2024.pdf'
import datesheetofMpharm1stsemmainandsupptheexamdipsarapr24 from './Datesheet Data/Datesheet of M.Pharm 1st Semester Main & Supplementary Theory Examination (DIPSAR), April 2024.pdf'
import datesheetofMphar1stsemmainsupptheexamspsapr24 from './Datesheet Data/Datesheet of M.Pharm 1st Semester Main & Supplementary Theory Examination (SPS), April 2024.pdf'
// import datesheetofmphar3rdsemexamdipsarspsapr24 from './Datesheet Data/Datesheet of M.Pharm 3rd Semester Examination (DIPSAR & SPS), April 2024.pdf'
import datesheetofmphar3rdsemexamdipsarspsapr24 from './Datesheet Data/Revised Datesheet of M.Pharm 3rd Semester Examination (DIPSAR & SPS), April 2024.pdf'
import msc3rdsempracticaldatesheetApr24 from './Datesheet Data/Date sheet of M.Sc. AIM 3rd Semester Practical Examination, April -2024.pdf'
import msc1stsempracticaldatesheetApr24 from './Datesheet Data/Date sheet of M.Sc. AIM 1st Semester Practical Examination, April-2024.pdf'
import datesheetofBpahrayurvedaiiiandvsempraexamapr24 from './Datesheet Data/Date Sheet of B. Pharm. (Ayurveda) III & V Semester (Practical) Examination, April - 2024.pdf'
import datesheetofDpharm2ndyearAnnualExammay24 from './Datesheet Data/Date sheet of D. Pharm. 2nd Year Annual Examination, May 2024.pdf'
import datesheetofDpharm1styearAnnualExammay24 from './Datesheet Data/Date sheet of D. Pharm. 1st Year Annual Examination, May 2024.pdf'
import datesheet0fmpharm1stsempractiocaexamdipsarmay24 from './Datesheet Data/Datesheet of M.Pharm 1st Semester Practical Examination (DIPSAR), May 2024.pdf'
import datesheetofmpharm1stsemspspracexammay24 from './Datesheet Data/Datesheet of M. Pharm 1st Sem (SPS) Practical Exams May- 2024.pdf'
import reviseddatesheetofmpharm1stsempharmacologydipdarmay24 from './Datesheet Data/Revised datesheet of M.Pharm 1st sem Pharmacology DIPSAR May-2024.pdf'
import datesheetofDpharm2ndyearsuppoldschemeexammay24 from './Datesheet Data/Date sheet of D.Pharm. 2nd Year Supplementary (Old Scheme) Examination, May-2024.pdf'
import datesheetofPrePhdtheoryexammay24 from './Datesheet Data/Date Sheet of Pre. Ph.D. Theory Examinations, May-2024.pdf'
import datesheetofbpharm8thsemthworyexamdipsarspsmay from './Datesheet Data/Datesheet of B. Pharm 8th Semester Theory Exams (DIPSAR & SPS) May- 2024.pdf'
import datesheetofBphar1stand3rdsemthorysuppspsandDipsarexammay24 from './Datesheet Data/Datesheet of B. Pharm 1st and 3rd Semester Theory Supple. (SPS and DIPSAR) Exams May 2024.pdf'
import datesheetofbpharm5thsemand7thsemthorusuppexammay24 from './Datesheet Data/Datesheet of B. Pharm 5th and 7th Semester Theory Supple. (SPS and DIPSAR) Exams May- 2024.pdf'
import datesheetofbschonsspoetsscience6thsemthooryexammay24 from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 6th Semester Theory Examinations, May-2024.pdf'
import datesheetofbpharm2nddandthsemmainsuppthexamdipsarjune24 from './Datesheet Data/Datesheet of BPharm 2nd and 6th Semester Main & Supplementary (Theory) Examination (DIPSAR & SPS), June 2024.pdf'
import dateshetosbpharm4thsemmainandsuppthorexamjun24 from './Datesheet Data/Datesheet of B.Pharm 4th Semester Main & Supplementary (Theory) Examination (DIPSAR & SPS), June 2024.pdf'
import datesheetofprephdpracticalexammay24 from './Datesheet Data/Date sheet of Pre-PhD Practical Examination, May-2024.pdf'
// import datesheetofbpt1stand7thsemsuppexamjune24 from './Datesheet Data/Date Sheet of BPT 1st & 7th Semester Supplementary Examinations, June-2024.pdf'
 import datesheetofbpt1stand7thsemsuppexamjune24 from './Datesheet Data/Date Sheet of BPT 1st & 7th Semester Supplementary Examinations, June-2024 Replaced File.pdf'
 import datesheetofDpahrm2ndyearpracticalexammay24 from './Datesheet Data/Date sheet of D.Pharm. 2nd Year Practical Examination, May-2024.pdf'
 import datesheetofBscHonssportsScience6thsempracticalexamjune24 from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 6th Semester Practical Examinations, June-2024.pdf'
 import DatesheetofDPharm1styearPracticalExaminationjune24 from './Datesheet Data/Date sheet of D.Pharm. 1st year Practical Examination, June-2024.pdf'
//  import datesheetofBpharmAyurveda2ndsemthoorymainandsemexamjune from './Datesheet Data/Datesheet of B. Pharm Ayurveda 2nd Semester (Theory) Main & Supple. Examination, June 2024.pdf'
 import DatesheetofBBACH6thsemexamjune from './Datesheet Data/Date sheet of BBA-CH 6th Semester Examination, June-2024.pdf'
 import RevisedDatesheetofBpahrmayrveda2ndesemthomainsuppuly from './Datesheet Data/Revised Datesheet of B. Pharm Ayurveda 2nd Semester (Theory) Main & Supple. Examination, July 2024.pdf'
 import datesheetofBpharm1st3rdand5thsempracticalsuppexamjune24 from './Datesheet Data/Datesheet of B. Pharm 1st, 3rd & 5th Semester Practical Supplementary Examination June-2024.pdf'
 import DatesheetofBpharm8thsemprojectvicavocaexamspsjune24 from './Datesheet Data/Datesheet of B. Pharm 8th Semester, Project Viva Voce Examination (SPS) June 2024.pdf'
 import datesheetofbpharm8thsemprojectvivavocaexamDipsarJunejune24 from './Datesheet Data/Datesheet of B. Pharm 8th Semester, Project Viva voce Examination (DIPSAR) June 2024.pdf'
 import RevisedDatesheetofbpt4thand8tthsemtheejul24 from './Datesheet Data/Revised Date Sheet of BPT 4th & 8th Semester Theory Examinations, July-2024.pdf'
 import RevisedDatesheetofBPT2ndand5thsemtheexamjuly24 from './Datesheet Data/Revised Date Sheet of BPT 2nd & 6th Semester Theory Examinations, July-2024.pdf'
 import datesheetofBpharm6thsemdipsarpractialexajune24 from './Datesheet Data/Datesheet of B. Pharm 6th Semester (DIPSAR) Practical Examination, June 2024.pdf'
 import datesheetofBpahrm3ndwsemdipsarpracticaexajune24 from './Datesheet Data/Datesheet of B. Pharm 2nd Semester (DIPSAR) Practical Examination, June 2024.pdf'
 import datesheetofBpharmayrveda2ndsemthorsuppexamjul24oldbatch from './Datesheet Data/Datesheet of B. Pharm Ayurveda 2nd Semester Theory Supple. Examination July- 2024 (Old Batch).pdf'
 import DatesheetofBpt5thsemsuppexamjuly24 from './Datesheet Data/Date Sheet of BPT 5th Semester Supplementary Examinations, July-2024.pdf'
 import datesheetofBpharm4thsempracticalexaminatonDipsarjune24 from './Datesheet Data/Datesheet of B.Pharm 4th Semester Practical Examination (DIPSAR), June 2024.pdf'
 import datesheetofBpharm2nd4thand6thsempracticalexamspsjune24 from './Datesheet Data/Datesheet of B.Pharm 2nd, 4th & 6th Semester Practical Examination (SPS), June 2024.pdf'
 import DatesheetofBscHonsSportsSciecnec2ndand4thsemtheexamjuly from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 2nd & 4th Semester Theory Examinations, July-2024.pdf'
 import datesheetofmscsopetscient4thsemtheoexamjuly24 from './Datesheet Data/Date Sheet of M. Sc. Sports Science 4th Semester Theory Examinations, July-2024.pdf'
 import dsofbschonssopetsscien2ndsemsupptheexamjulyforoldschemestudent from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 2nd Semester Supplementary Theory Examinations, July-2024 (For Old Scheme Students).pdf'
 import datesheetofMptsportsmedicincardicularandpulmonaryandpedi2ndsemtheexamjuly24 from './Datesheet Data/Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 2nd Semester Theory Examination, July-2024.pdf'
 import datesheetofmptssmcpo4thsemtheexamjul24 from './Datesheet Data/Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 4th Semester Theory Examination, July-2024.pdf'
 import datesheerofBpt8thsempracticalexamjuly24 from './Datesheet Data/Date Sheet of BPT 8th Semester Practical Examination, July-2024.pdf'
 import dateshetofodmltitsemtheexamjul24 from './Datesheet Data/Date Sheet of DMLT II Semester (Theory) Examination, July - 2024.pdf'
 import bmltof2ndsemexamjul24 from './Datesheet Data/Date Sheet of BMLT II & IV Semester (Theory) Examination, July-2024.pdf'
 import datesheetobbaheathcareiiandfourtsemthexamjul24 from './Datesheet Data/Date Sheet of BBA (Healthcare) II & IV Semester (Theory) Examination, July– 2024.pdf'
 import datesheetoofbpt4thsempracticalexamjuly24 from './Datesheet Data/Date Sheet of BPT 4th Semester Practical Examination, July-2024.pdf'
 import datesheetofBscbiomedicalitandivsemthoexamaug24 from './Datesheet Data/Date Sheet of B.Sc. Biomedical II & IV Semester (Theory) Examination, August-2024.pdf'
 import datesheetoofmptspmecardandpulmonadpe4thsempraexamaug24 from './Datesheet Data/Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 4th Semester Practical Examination, August-2024.pdf'
 import datesheetofsepmedcarpu2ndsempracexjul24 from './Datesheet Data/Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 2nd Semester Practical Examination, July-2024.pdf'
 import datesheetobBschonssportsscience2ndsempracticalexamaug24 from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 2nd Semester Practical Examinations, August-2024.pdf'
 import datesheetoofBpahrmayurveda2ndsempracticalexamaug24 from './Datesheet Data/Datesheet of B. Pharm (Ayurveda) 2nd Semester Practical Examination, August 2024.pdf'
 import datesheetofBpharmayurveda2ndsempracticalsuppexamoldbatch24 from './Datesheet Data/Datesheet of B. Pharm (Ayurveda) 2nd Semester Practical supple. Examination Old Batch August 2024.pdf'
 import datesheetofBPT2ndsempracticalexamaug24 from './Datesheet Data/Date Sheet of BPT 2nd Semester Practical Examination, August-2024.pdf'
 import datesheetofBPT6thsempracticalexamaug24 from './Datesheet Data/Date Sheet of BPT 6th Semester Practical Examination, August-2024.pdf'
 import datesheetofbpharmayrveda6thsemtheexamaug24 from './Datesheet Data/Datesheet of B. Pharm Ayurveda 6th Semester Theory Examination, August 2024.pdf'
 import datesheerofMBAITM2ndsemexamaug24 from './Datesheet Data/Date Sheet of MBA-ITM 2nd semester Examination, August – 2024.pdf'
 import DatesheerofMBApmHHMandMPM2ndsemexamaug24 from './Datesheet Data/Date Sheet of MBA-PM,HHM and MPH 2nd semester Examination, August – 2024.pdf'
 import datesheetofMPTsportsmedicine2ndsempracexamaug24 from './Datesheet Data/Date Sheet of MPT (Sports Medicine) 2nd Semester Practical Examinations, August-2024.pdf'
 import datesheetofbschonssportsscience4thsempracticalexamaug24 from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 4th Semester Practical Examinations, Aug-2024.pdf'
 import datesheetofomscsportscienc4thsempracexamaug24 from './Datesheet Data/Date Sheet of M. Sc. Sports Sciecne 4th Semester Practical Examinations, Aug-2024.pdf'
 import DatesheetofMscAim2ndsemesterexaminationsept24 from './Datesheet Data/Date sheet of M.Sc. AIM 2nd Semester Examination, September-2024.pdf'
 import datesheetofBMLT6thsemexamsept24 from './Datesheet Data/Date sheet of BMLT 6th Semester Examination, September -2024.pdf'
 import DatesheetofDMLT4thsemsterexaminationsept24 from './Datesheet Data/Date sheet of DMLT 4th Semester Examination, September-2024.pdf'
 import bscbiomed2ndsempracticalsuppexamspet24 from './Datesheet Data/B.Sc Biomed 2nd sem practical (supplementary) Examination, September-2024.pdf'
 import mscaim4thsemexamsept2024 from './Datesheet Data/M.Sc. AIM 4th Semester Examination, September-2024.pdf'
 import Datesheetofbpharmayurveda4thsemmainandsuppexamsep24 from './Datesheet Data/Datesheet of B. Pharm (Ayurveda) 4th Sem Main & Supple. Exam, September 2024.pdf'
 import DatesheetofDMLT4thsmepracticalexam24 from './Datesheet Data/Date sheet of DMLT 4th Semester (Practical) Examination, 2024.pdf'
 import datesheetofbpharmayurveda4thsempracticalexamspsoct24 from './Datesheet Data/Datesheet of B.Pharm Ayurveda 4th Semester Practical Examination (SPS), October 2024.pdf'
 import datesheetofmpahr2ndsemspstheoct24 from './Datesheet Data/Datesheet of M.  Pharm 2nd Semester (SPS) Theory, October 2024.pdf'
 import datesheetofmpahr2ndsemDipsartheoct24 from './Datesheet Data/Datesheet of M.  Pharm 2nd Semester (DIPSAR) Theory, October 2024.pdf'
 import DatesheetofMpahrm2ndsemspsexamnov24 from './Datesheet Data/Datesheet of M. Pharm 2nd Semester (SPS) Practical Exams, November 2024.pdf'
 import doMPharm2ndsempracticalExamforDraandexeDRSNov24 from './Datesheet Data/Datesheet of M.Pharm 2nd Semester Practical Examination for DRA & Exe- DRS, November 2024.pdf'
 import mpharm2ndsempracticalexamdipsarnov24 from './Datesheet Data/Datesheet of M.Pharm 2nd Semester Practical Examination (DIPSAR), November 2024.pdf'
 import DatesheetofDpharm1stand2ndyearsuppexamnov2024 from './Datesheet Data/Date Sheet of D.Pharm. 1st & 2nd Year Supplementary Examination, November-2024.pdf'
 import datesheetofBPt3rdsemsupptheoexamnov24 from './Datesheet Data/Date Sheet of BPT 3rd Semester Supplementary Theory Examinations, November-2024.pdf'
 import datesheetofMpharm1stsemthospsanddipsarsuppexamNov24 from './Datesheet Data/Datesheet of M. Pharm 1st Semester Theory (SPS & DIPSAR) Supple. Exams, November 2024.pdf'
 import datesheetofBpt3rdsemsupppracticalexamdec24 from './Datesheet Data/Date Sheet of BPT 3rd Semester Supplementary Practical Examination. December-2024.pdf'
 import DatesheetofMpharm1stsemsupppracticalexamdec24 from './Datesheet Data/Datesheet of M. Pharm 1st Semester Supple. Practical Examination, December 2024.pdf'
 import datesheetofbpt1stand7thsemthoexamjan25 from './Datesheet Data/Date Sheet of BPT 1st & 7th Semester Theory Examination, January-2025.pdf'
 import datesheetofmptsmcandpp1stsemexamjan25 from './Datesheet Data/Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 1st Semester Theory Examinaiton, January-2025.pdf'
 import Datesheetofmptttcpp3rdsemexamjan25 from './Datesheet Data/Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 3rd Semester Theory Examinaiton, January-2025.pdf'
 import datesheetofBpt3rdand5thsemthoexamjan24 from './Datesheet Data/Date Sheet of BPT 3rd & 5th Semester Theory Examination, January-2025 (1).pdf'
 import DatesheetofBpharm1stand5thsemmainandsupptheexamjan25 from './Datesheet Data/Datesheet of B.Pharm 1st & 5th Semester Main & Supple. Theory Examination, January 2025.pdf'
 import DatesheetofBpharm3rdand7thsemmainandsupptheexamjan25 from './Datesheet Data/Datesheet of B.Pharm 3rd & 7th Semester Main & Supple. Theory Examination, January 2025.pdf'
 import DatesheetOfBBACH1stand3rdsemsuppexamjan25 from './Datesheet Data/Date Sheet of BBA-CH 1st & 3rd Semester Supplementary Examination, January-2025.pdf'
 import DatesheetofBMLT5thsemexamjan25 from './Datesheet Data/Date Sheet of BMLT 5th Semester Examination, January-2025.pdf'
//  import DatesheetofBBAHC1stand3rdsemexamjan25 from './Datesheet Data/Date Sheet of BBA-HC 1st & 3rd Semester Examination, January-2025.pdf'
 import DatesheetofBBAHC1stand3rdsemexamjan25 from './Datesheet Data/Date Sheet of BBA-HC 1st & 3rd Semester Examination, January-2025 (1).pdf'
 import datesheetofBBACH5thsemjan25 from './Datesheet Data/Date Sheet of BBA-CH 5th Semester Supplemenatry Examination, January-2025.pdf'
 import datesheetofBMLT1stand3rdsemjan24 from './Datesheet Data/Date Sheet of BMLT 1st & 3rd Semester Examination, January-2025.pdf'
 import DatesheetofDMLT1stand3rdsemexamjan24 from './Datesheet Data/Date Sheet of DMLT 1st & 3rd Semester Examination, January-2025.pdf'
 import RevisedDatesheetofMPtSportsScienceMedicineCardiovascilarandpulmo2025 from './Datesheet Data/Revised Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 3rd Semester Theory Examinaiton, January-2025.pdf'
 import ReviseddatesheetofMPTsmcarpulmpedi1stsemthoexam2025 from './Datesheet Data/Revised Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 1st Semester Theory Examinaiton, January-2025.pdf'
 import VivavocaofMpharmphama4thsemexamspsjan25 from './Datesheet Data/Viva- Voce Notice of M.Pharm (Pharmacognosy) 4th Semester Exam. (SPS), January 2025.pdf'
 import RevisedDatesheeofBMLT1stand3rdsemxamjan24 from './Datesheet Data/Revised Date Sheet of BMLT 1st & 3rd Semester Examination, January-2025.pdf'
 import RevisedDatesheetofBMLT5thsemexamJan2025 from './Datesheet Data/Revised Date Sheet of BMLT 5th Semester Examination, January-2025.pdf'
 import datesheetofMbaitm3rdsemexamjan25 from './Datesheet Data/Date Sheet of MBA-PM & MBA-ITM 3rd Semester Examinations, January-2025.pdf'
 import DatesheetofBschonsasportsscience3rdsem5thsem from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 3rd & 5th Semester Theory Examination, January-2025.pdf'
 import DatehseetofMBAHHM3rdsemexamjan25 from './Datesheet Data/Date Sheet of MBA-HHM and MPH 3rd Semester Examinations, January-2025.pdf'
 import DatesheetofBSCHonssportsScience1stsemtheexamJan25 from './Datesheet Data/Date Sheet of B. Sc. (Hons.) Sports Science 1st Semester Theory Examination. January-2025.pdf'
 import bpharm1stsemdatesheet25 from './Datesheet Data/Datesheet of B. Pharm (Ayurveda) 1st Sem Main and Supple. theory Exams, January 2025.pdf'
 import bharm3rdand5thsemdatesheetAurveda2025 from './Datesheet Data/Datesheet of B. Pharm (Ayurveda) 3rd and 7th Sem Main and Supple. theory Exams, January 2025.pdf'
 import DatesheetofBBAH5thsemexamjan25 from './Datesheet Data/Date Sheet of BBA-HC V Semester Examination, January-2025.pdf'
 import DatesheetofDpharmstand2ndyearsupppracticalexamjan25 from './Datesheet Data/Date Sheet of D.Pharm. I & II Year Supplementary Practical Examination, January-2025.pdf'


const DateSheet = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">Datesheet</h2>

      <div className="results-data-link-container">
        <ul className="datesheet-ul-container">
          <li>
            <a href={DatesheetofDpharmstand2ndyearsupppracticalexamjan25}>
            Date Sheet of D.Pharm. I & II Year Supplementary Practical Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBBAH5thsemexamjan25}>
            Date Sheet of BBA-HC V Semester Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={bharm3rdand5thsemdatesheetAurveda2025}>
            Datesheet of B. Pharm (Ayurveda) 3rd and 7th Sem Main and Supple. theory Exams, January 2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={bpharm1stsemdatesheet25}>
            Datesheet of B. Pharm (Ayurveda) 1st Sem Main and Supple. theory Exams, January 2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBSCHonssportsScience1stsemtheexamJan25}>
            Date Sheet of B. Sc. (Hons.) Sports Science 1st Semester Theory Examination. January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatehseetofMBAHHM3rdsemexamjan25}>
            Date Sheet of MBA-HHM and MPH 3rd Semester Examinations, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBschonsasportsscience3rdsem5thsem}>
            Date Sheet of B. Sc. (Hons.) Sports Science 3rd & 5th Semester Theory Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofMbaitm3rdsemexamjan25}>
            Date Sheet of MBA-PM & MBA-ITM 3rd Semester Examinations, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={RevisedDatesheetofBMLT5thsemexamJan2025}>
            Revised Date Sheet of BMLT 5th Semester Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={RevisedDatesheeofBMLT1stand3rdsemxamjan24}>
            Revised Date Sheet of BMLT 1st & 3rd Semester Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={VivavocaofMpharmphama4thsemexamspsjan25}>
            Viva- Voce Notice of M.Pharm (Pharmacognosy) 4th Semester Exam. (SPS), January 2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={ReviseddatesheetofMPTsmcarpulmpedi1stsemthoexam2025}>
            Revised Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 1st Semester Theory Examinaiton, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={RevisedDatesheetofMPtSportsScienceMedicineCardiovascilarandpulmo2025}>
            Revised Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 3rd Semester Theory Examinaiton, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofDMLT1stand3rdsemexamjan24}>
            Date Sheet of DMLT 1st & 3rd Semester Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBMLT1stand3rdsemjan24}>
            Date Sheet of BMLT 1st & 3rd Semester Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBBACH5thsemjan25}>
            Date Sheet of BBA-CH 5th Semester Supplemenatry Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBBAHC1stand3rdsemexamjan25}>
            Date Sheet of BBA-HC 1st & 3rd Semester Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBMLT5thsemexamjan25}>
            Date Sheet of BMLT 5th Semester Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetOfBBACH1stand3rdsemsuppexamjan25}>
            Date Sheet of BBA-CH 1st & 3rd Semester Supplementary Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBpharm3rdand7thsemmainandsupptheexamjan25}>
            Datesheet of B.Pharm 3rd & 7th Semester Main & Supple. Theory Examination, January 2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBpharm1stand5thsemmainandsupptheexamjan25}>
            Datesheet of B.Pharm 1st & 5th Semester Main & Supple. Theory Examination, January 2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBpt3rdand5thsemthoexamjan24}>
            Date Sheet of BPT 3rd & 5th Semester Theory Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={Datesheetofmptttcpp3rdsemexamjan25}>
            Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 3rd Semester Theory Examinaiton, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofmptsmcandpp1stsemexamjan25}>
            Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 1st Semester Theory Examinaiton, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofbpt1stand7thsemthoexamjan25}>
            Date Sheet of BPT 1st & 7th Semester Theory Examination, January-2025
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofMpharm1stsemsupppracticalexamdec24}>
            Datesheet of M. Pharm 1st Semester Supple. Practical Examination, December 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBpt3rdsemsupppracticalexamdec24}>
            Date Sheet of BPT 3rd Semester Supplementary Practical Examination. December-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofMpharm1stsemthospsanddipsarsuppexamNov24}>
            Datesheet of M. Pharm 1st Semester Theory (SPS & DIPSAR) Supple. Exams, November 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBPt3rdsemsupptheoexamnov24}>
            Date Sheet of BPT 3rd Semester Supplementary Theory Examinations, November-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofDpharm1stand2ndyearsuppexamnov2024}>
            Date Sheet of D.Pharm. 1st & 2nd Year Supplementary Examination, November-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={mpharm2ndsempracticalexamdipsarnov24}>
            Datesheet of M.Pharm 2nd Semester Practical Examination (DIPSAR), November 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={doMPharm2ndsempracticalExamforDraandexeDRSNov24}>
            Datesheet of M.Pharm 2nd Semester Practical Examination for DRA & Exe- DRS, November 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofMpahrm2ndsemspsexamnov24}>
            Datesheet of M. Pharm 2nd Semester (SPS) Practical Exams, November 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofmpahr2ndsemspstheoct24}>
            Datesheet of M. Pharm 2nd Semester (SPS) Theory, October 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofmpahr2ndsemDipsartheoct24}>
            Datesheet of M.  Pharm 2nd Semester (DIPSAR) Theory, October 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofbpharmayurveda4thsempracticalexamspsoct24}>
            Datesheet of B.Pharm Ayurveda 4th Semester Practical Examination (SPS), October 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofDMLT4thsmepracticalexam24}>
            Date sheet of DMLT 4th Semester (Practical) Examination, 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={Datesheetofbpharmayurveda4thsemmainandsuppexamsep24}>
            Datesheet of B. Pharm (Ayurveda) 4th Sem Main & Supple. Exam, September 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={mscaim4thsemexamsept2024}>
            M.Sc. AIM 4th Semester Examination, September-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={bscbiomed2ndsempracticalsuppexamspet24}>
            B.Sc Biomed 2nd sem practical (supplementary) Examination, September-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofDMLT4thsemsterexaminationsept24}>
            Date sheet of DMLT 4th Semester Examination, September-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBMLT6thsemexamsept24}>
            Date sheet of BMLT 6th Semester Examination, September -2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>

          <li>
            <a href={DatesheetofMscAim2ndsemesterexaminationsept24}>
            Date sheet of M.Sc. AIM 2nd Semester Examination, September-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>

          <li>
            <a href={datesheetofomscsportscienc4thsempracexamaug24}>
            Date Sheet of M. Sc. Sports Sciecne 4th Semester Practical Examinations, Aug-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofbschonssportsscience4thsempracticalexamaug24}>
            Date Sheet of B. Sc. (Hons.) Sports Science 4th Semester Practical Examinations, Aug-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofMPTsportsmedicine2ndsempracexamaug24}>
            Date Sheet of MPT (Sports Medicine) 2nd Semester Practical Examinations, August-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheerofMBApmHHMandMPM2ndsemexamaug24}>
            Date Sheet of MBA-PM,HHM and MPH 2nd semester Examination, August – 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheerofMBAITM2ndsemexamaug24}>
            Date Sheet of MBA-ITM 2nd semester Examination, August – 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofbpharmayrveda6thsemtheexamaug24}>
            Datesheet of B. Pharm Ayurveda 6th Semester Theory Examination, August 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBPT6thsempracticalexamaug24}>
            Date Sheet of BPT 6th Semester Practical Examination, August-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBPT2ndsempracticalexamaug24}>
            Date Sheet of BPT 2nd Semester Practical Examination, August-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBpharmayurveda2ndsempracticalsuppexamoldbatch24}>
            Datesheet of B. Pharm (Ayurveda) 2nd Semester Practical supple. Examination Old Batch August 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetoofBpahrmayurveda2ndsempracticalexamaug24}>
            Datesheet of B. Pharm (Ayurveda) 2nd Semester Practical Examination, August 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetobBschonssportsscience2ndsempracticalexamaug24}>
            Date Sheet of B. Sc. (Hons.) Sports Science 2nd Semester Practical Examinations, August-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofsepmedcarpu2ndsempracexjul24}>
            Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 2nd Semester Practical Examination, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetoofmptspmecardandpulmonadpe4thsempraexamaug24}>
            Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 4th Semester Practical Examination, August-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBscbiomedicalitandivsemthoexamaug24}>
            Date Sheet of B.Sc. Biomedical II & IV Semester (Theory) Examination, August-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetoofbpt4thsempracticalexamjuly24}>
            Date Sheet of BPT 4th Semester Practical Examination, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetobbaheathcareiiandfourtsemthexamjul24}>
            Date Sheet of BBA (Healthcare) II & IV Semester (Theory) Examination, July– 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={bmltof2ndsemexamjul24}>
            Date Sheet of BMLT II & IV Semester (Theory) Examination, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={dateshetofodmltitsemtheexamjul24}>
            Date Sheet of DMLT II Semester (Theory) Examination, July - 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheerofBpt8thsempracticalexamjuly24}>
            Date Sheet of BPT 8th Semester Practical Examination, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofmptssmcpo4thsemtheexamjul24}>
            Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 4th Semester Theory Examination, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofMptsportsmedicincardicularandpulmonaryandpedi2ndsemtheexamjuly24}>
            Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 2nd Semester Theory Examination, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={dsofbschonssopetsscien2ndsemsupptheexamjulyforoldschemestudent}>
            Date Sheet of B. Sc. (Hons.) Sports Science 2nd Semester Supplementary Theory Examinations, July-2024 (For Old Scheme Students)
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofmscsopetscient4thsemtheoexamjuly24}>
            Date Sheet of M. Sc. Sports Science 4th Semester Theory Examinations, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBscHonsSportsSciecnec2ndand4thsemtheexamjuly}>
            Date Sheet of B. Sc. (Hons.) Sports Science 2nd & 4th Semester Theory Examinations, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBpharm2nd4thand6thsempracticalexamspsjune24}>
            Datesheet of B.Pharm 2nd, 4th & 6th Semester Practical Examination (SPS), June 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBpharm4thsempracticalexaminatonDipsarjune24}>
            Datesheet of B.Pharm 4th Semester Practical Examination (DIPSAR), June 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBpt5thsemsuppexamjuly24}>
            Date Sheet of BPT 5th Semester Supplementary Examinations, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBpharmayrveda2ndsemthorsuppexamjul24oldbatch}>
            Datesheet of B. Pharm Ayurveda 2nd Semester Theory Supple. Examination July- 2024 (Old Batch)
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBpahrm3ndwsemdipsarpracticaexajune24}>
            Datesheet of B. Pharm 2nd Semester (DIPSAR) Practical Examination, June 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBpharm6thsemdipsarpractialexajune24}>
            Datesheet of B. Pharm 6th Semester (DIPSAR) Practical Examination, June 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={RevisedDatesheetofBPT2ndand5thsemtheexamjuly24}>
            Revised Date Sheet of BPT 2nd & 6th Semester Theory Examinations, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={RevisedDatesheetofbpt4thand8tthsemtheejul24}>
            Revised Date Sheet of BPT 4th & 8th Semester Theory Examinations, July-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofbpharm8thsemprojectvivavocaexamDipsarJunejune24}>
            Datesheet of B. Pharm 8th Semester, Project Viva voce Examination (DIPSAR) June 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBpharm8thsemprojectvicavocaexamspsjune24}>
            Datesheet of B. Pharm 8th Semester, Project Viva Voce Examination (SPS) June 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBpharm1st3rdand5thsempracticalsuppexamjune24}>
            Datesheet of B. Pharm 1st, 3rd & 5th Semester Practical Supplementary Examination June-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={RevisedDatesheetofBpahrmayrveda2ndesemthomainsuppuly}>
            Revised Datesheet of B. Pharm Ayurveda 2nd Semester (Theory) Main & Supple. Examination, July 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={DatesheetofBBACH6thsemexamjune}>
            Date sheet of BBA-CH 6th Semester Examination, June-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          {/* <li>
            <a href={datesheetofBpharmAyurveda2ndsemthoorymainandsemexamjune}>
            Datesheet of B. Pharm Ayurveda 2nd Semester (Theory) Main & Supple. Examination, June 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li> */}
          <li>
            <a href={DatesheetofDPharm1styearPracticalExaminationjune24}>
            Date sheet of D.Pharm. 1st year Practical Examination, June-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBscHonssportsScience6thsempracticalexamjune24}>
            Date Sheet of B. Sc. (Hons.) Sports Science 6th Semester Practical Examinations, June-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofDpahrm2ndyearpracticalexammay24}>
            Date sheet of D.Pharm. 2nd Year Practical Examination, May-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofbpt1stand7thsemsuppexamjune24}>
            Date Sheet of BPT 1st & 7th Semester Supplementary Examinations, June-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofprephdpracticalexammay24}>
            Date sheet of Pre-PhD Practical Examination, May-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={dateshetosbpharm4thsemmainandsuppthorexamjun24}>
            Datesheet of B.Pharm 4th Semester Main & Supplementary (Theory) Examination (DIPSAR & SPS), June 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofbpharm2nddandthsemmainsuppthexamdipsarjune24}>
            Datesheet of BPharm 2nd and 6th Semester Main & Supplementary (Theory) Examination (DIPSAR & SPS), June 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofbschonsspoetsscience6thsemthooryexammay24}>
            Date Sheet of B. Sc. (Hons.) Sports Science 6th Semester Theory Examinations, May-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofbpharm5thsemand7thsemthorusuppexammay24}>
            Datesheet of B. Pharm 5th and 7th Semester Theory Supple. (SPS and DIPSAR) Exams May- 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBphar1stand3rdsemthorysuppspsandDipsarexammay24}>
            Datesheet of B. Pharm 1st and 3rd Semester Theory Supple. (SPS and DIPSAR) Exams May 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofbpharm8thsemthworyexamdipsarspsmay}>
            Datesheet of B. Pharm 8th Semester Theory Exams (DIPSAR & SPS) May- 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofPrePhdtheoryexammay24}>
            Date Sheet of Pre. Ph.D. Theory Examinations, May-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofDpharm2ndyearsuppoldschemeexammay24}>
            Date sheet of D.Pharm. 2nd Year Supplementary (Old Scheme) Examination, May-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={reviseddatesheetofmpharm1stsempharmacologydipdarmay24}>
            Revised datesheet of M.Pharm 1st sem Pharmacology DIPSAR May-2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofmpharm1stsemspspracexammay24}>
            Datesheet of M. Pharm 1st Sem (SPS) Practical Exams May- 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheet0fmpharm1stsempractiocaexamdipsarmay24}>
            Datesheet of M.Pharm 1st Semester Practical Examination (DIPSAR), May 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofDpharm1styearAnnualExammay24}>
            Date sheet of D. Pharm. 1st Year Annual Examination, May 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofDpharm2ndyearAnnualExammay24}>
            Date sheet of D. Pharm. 2nd Year Annual Examination, May 2024
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={datesheetofBpahrayurvedaiiiandvsempraexamapr24}>
            Date Sheet of B. Pharm. (Ayurveda) III & V Semester (Practical) Examination, April - 2024
            </a>{" "}
          
          </li>
          <li>
            <a href={msc1stsempracticaldatesheetApr24}>
            Date sheet of M.Sc. AIM 1st Semester Practical Examination, April-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={msc3rdsempracticaldatesheetApr24}>
          
            Date sheet of M.Sc. AIM 3rd Semester Practical Examination, April -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofmphar3rdsemexamdipsarspsapr24}>
            {/* Datesheet of M.Pharm 3rd Semester Examination (DIPSAR & SPS), April 2024 */}
            Revised Datesheet of M.Pharm 3rd Semester Examination (DIPSAR & SPS), April 2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofMphar1stsemmainsupptheexamspsapr24}>
            Datesheet of M.Pharm 1st Semester Main & Supplementary Theory Examination (SPS), April 2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofMpharm1stsemmainandsupptheexamdipsarapr24}>
            Datesheet of M.Pharm 1st Semester Main & Supplementary Theory Examination (DIPSAR), April 2024
            </a>{" "}
          
          </li>
          <li>
            <a href={mscAimand3rdsemtheoexamApr24}>
            Date of M. Sc. AIM 1st and 3rd Semester Theory Examination, April -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bpt3rdand5thsempracticalexamapr24}>
            Date sheet of BPT 3rd and 5th Semester Practical Examination, April -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofBpharmAyruveda3rdand5thsenthoexamapr24}>
            Datesheet of B.Pharm  Ayurveda 3rd & 5th Semester Theory Examination, April 2024
            </a>{" "}
          
          </li>
          <li>
            <a href={mptsportsMedicineCardiovascularandpul3rdsempracexammar24}>
            Date Sheet of MPT(Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 3rd Semester Practical Examinations, March-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dsofbschonssprotscience3rdsempracexamnar24}>
            Date Sheet of B. Sc. (Hons.) Sports Science 3rd Semester Practical Examinations, March-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofmscsportsscience3rdsempraexammar24}>
            Date Sheet of M. Sc. Sports Science 3rd Semester Practical Examinations, March-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={revisedDatesheetofBptrdSemand5thsemtheoryexam24}>
            Revised Date sheet of BPT 3rd & 5th Semester Theory Examination, March-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dataSheetOfMptSportMedicineCardiovascularAndpul3rdthoexam}>
            Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary & Pediatrics) 3rd Semester Theory Examinations
            </a>{" "}
          
          </li>
          <li>
            <a href={BPT3rdand5thsemthoexammar24}>
            Date Sheet of BPT 3rd & 5th Semester Theory Examinations, March-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofccyPracticalExaminationmarch2024}>
            Date sheet of CCY (Practical) Examination, March-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofccyTheoryExaminationmarch2024}>
            Date sheet of CCY (Theory) Examination, March-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dsofbpharmayurveda1stsempracticalexamspsfeb24}>
            Datesheet of B.Pharm Ayurveda 1st Semester Practical Examination SPS, February 2024 
            </a>{" "}
          
          </li>
          <li>
            <a href={dsofMscHonssportsScience3rdsemthoexammar24}>
            Date Sheet of M. Sc. Sports Science 3rd Semester Theory Examinations, March-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dsofbscHonssportsScience3rdsemthoexammar24}>
            Date Sheet of B. Sc. (Hons.) Sports Science 3rd Semester Theory Examinations, March-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dsofBscBio3rdsempractexamfeb24}>
            Date sheet of B.Sc. Biomedical 3rd Semester (Practical) Examination, February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dsofbschons1stsempractexamfeb24}>
            Date sheet of B.Sc. (Hons.) Sports Science 1st semester (Practical) Examination, March -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetOfBMLT3rdsempracexamfeb24}>
            Date sheet of BMLT 3rd Semester (Practical) Examination, February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofDMLT3rdsempracticalexamfeb24}>
            Date sheet of DMLT 3rd Semester (Practical) Examination, February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dsofbmlt1stsempracticalexamfeb24}>
            Date sheet of BMLT 1st Semester (Practical) Examination, February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dsofdmlt1stsempracticalexamfeb24}>
            Date sheet of DMLT 1st Semester (Practical) Examination, February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dsofbmlt5thsempractiocalexamfeb24}>
            Date sheet of BMLT 5th Semester (Practical) Examination, February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofbscBiomedicalscience1stsemprac}>
            Date sheet of B.Sc. (Biomedical Sciences) 1st Semester Practical Examination, Feb-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetOfBschonssportsSciecce5thsempracticalexam24}>
            Date Sheet of B. Sc. (Hons.) Sports Science 5th Semester Practical Examinations, Feb-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetpfpbt1stsempracexam24}>
            Date Sheet of BPT 1st Semester Practical Examinations, Feb-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofapbt7thsempracticaexamfeb24}>
            Date Sheet of BPT 7th Semester Practical Examinations, Feb-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={datesheetofmptsprotmedicinecadiolpr1stsemfeb24}>
            Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary, Pediatrics) 1st Semester Practical Examinations, Feb-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={mbaitmmbapm1stsemthoexamfeb24}>
              Date Sheet of MBA(PM, ITM & HHM) & MPH 1st Semester Theory
              Examination, February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={mbaitmmbapm3rdsemthoexamfeb24}>
              Date Sheet of MBA ITM & MBA PM 3rd Semester Theory Examination,
              February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={mbahhmmph3rdsemtheexfeb24}>
              Date Sheet of MBA-HHM & MPH 3rd Semester Theory Examination,
              February-2024
            </a>{" "}
          
          </li>

          <li>
            <a href={corigendrumDatesheetBPharm3rdsempraExam}>
              Corrigendum Datesheet of B.Pharm 3rd Sem. Practical Exam. DIPSAR,
              February 2024
            </a>{" "}
          
          </li>

          <li>
            <a href={Bpharm7thsemPracticalDatesheetDipsarfeb24}>
              B.Pharm 7th Semester Practical Datesheet (DIPSAR), February 2024
            </a>{" "}
          
          </li>

          <li>
            <a href={Bpharm5thsemPracticalDatesheetDipsarFeb24}>
              B.Pharm 5th Semester Practical Datesheet (DIPSAR), February 2024
            </a>{" "}
          
          </li>

          <li>
            <a href={Bpharm3rdsempraticalDatesheetDipsarfeb24}>
              B.Pharm 3rd Semester Practical Datesheet (DIPSAR), February 2024
            </a>{" "}
          
          </li>

          <li>
            <a href={bpharm1stsemparticaldatesheetDipsarfeb24}>
              B.Pharm 1st Semester Practical Datesheet (DIPSAR), February 2024
            </a>{" "}
          
          </li>

          <li>
            <a href={Bpharm1stsempracticaldatesheetSPSfeb24}>
              B.Pharm 1st Semester Practical Datesheet (SPS), February 2024
            </a>{" "}
          
          </li>

          <li>
            <a href={Bpharm3rdsempracticaldatesheetSPSfeb24}>
              B.Pharm 3rd Semester Practical Datesheet (SPS), February 2024
            </a>{" "}
          
          </li>
          <li>
            <a href={Bpharm5thsempracticaldatesheetSPSfeb24}>
              B.Pharm 5th Semester Practical Datesheet (SPS), February 2024
            </a>{" "}
          
          </li>
          <li>
            <a href={Bpharm7thsempracticaldatesheetSPSfeb24}>
              B.Pharm 7th Semester Practical Datesheet (SPS), February 2024
            </a>{" "}
          
          </li>

          <li>
            <a href={bschonsBiomediacalsciences1stsemsuppexamfeb24}>
              Date Sheet of B.Sc. (Hons.) Biomedical Sciences 1st Semester
              Supple. Examination, February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bscbiomedicalscience3rdsemthoexamfeb24}>
              Date Sheet of B.Sc. (Hons.) Biomedical Sciences 3rd Semester
              Theory Examination, February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={Bpharmayrveda1stsemthorexamfeb24}>
              Date Sheet of B.Pharm (Ayurveda) 1st Semester Theory Examination,
              February -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={BschonsbiomedicalScience1stsemthoryexamfeb24}>
              Date Sheet of B.Sc. (Hons.) Biomedical Sciences 1st Semester
              Theory Examination, February-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={mptsportmediciecadiandpi1stsem}>
              Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary,
              Pediatrics) 1st Semester Theory Examinations, Feb-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bscss1stsemthoexamfeb24}>
              Date Sheet of B. Sc. (Hons.) Sports Science 1st Semester Theory
              Examinations, Feb-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bschonssprotscience5thsemthoexamfeb24}>
              Date Sheet of B. Sc. (Hons.) Sports Science 5th Semester Theory
              Examinations, Feb-2024
            </a>{" "}
          
          </li>

          <li>
            <a href={bbach3rdsemsuppexamfeb24}>
              Date sheet of BBA-CH 1st and 3rd Semester Supplementary
              Examination, February -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bmlt3rdsemexamfeb24}>
              Date sheet of BMLT 3rd Semester Examination, February -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dmlt3rdsemexamfeb24}>
              Date sheet of DMLT 3rd Semester Examination, February -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bbahc5rdsemfeb24}>
              Date sheet of BBA-CH 5th Semester Examination, February -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bbahc3rdsemfeb24}>
              Date sheet of BBA-HC 3rd Semester Examination, February -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bmlt5stsemexamfeb24}>
              Date sheet of BMLT 5th Semester Examination, February -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bmlt1stsemexamfeb24}>
              Date sheet of BMLT 1st Semester Examination, February -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bbahc1stsemexamfeb24}>
              Date sheet of BBA-HC 1st Semester Examination, February -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dmlt1stsemexamfeb24}>
              Date sheet of DMLT 1st Semester Examination, February -2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bpt1stand7thsemtheoryexamfeb23}>
              Date Sheet of BPT 1st & 7th Semester Theory Examinations, Feb-2023
            </a>{" "}
          
          </li>

          <li>
            <a href={bpharm1stand5thsemtheoryexam}>
              Datesheet of B.Pharm 1st & 5th Semester Theory Examination (DIPSAR
              & SPS), January 2024
            </a>{" "}
          
          </li>
          <li>
            <a href={bharm3rdand7thsemtheoex}>
              Datesheet of B.Pharm 3rd & 7th Semester Theory Examination (DIPSAR
              & SPS), January 2024
            </a>{" "}
          
          </li>
          <li>
            <a href={sprotsandyogawcsc1stand2ndsemsupppracticalexaminationjan}>
              Date Sheet of Sports Assessment & Yoga Application (WCSC) 1st and
              2nd Semester Supplementary Practical Examination, January-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={medicinemanagementwcsc1styearand2ndsem}>
              Date Sheet of Medicine Management (WCSC) 1st and 2nd Semester
              Supplementary Practical Examination, January-2024
            </a>{" "}
          
          </li>
          <li>
            <a href={dpharm1stand2ndsuppexamdec2023}>
              Date sheet of D.Pharm. 1st & 2nd Year Supplementary Examination
              December -2023
            </a>
          </li>
          <li>
            <a href={dsofmmcwcscthoexam}>
              Date Sheet of MMC (WCSC) 1st & 2nd Semester Supplementary Theory
              Examinations, Dec-2023
            </a>
          </li>
          <li>
            <a href={dsofsayawcsc1nd2ndsem}>
              Date Sheet of SAYA (WCSC) 1st & 2nd Semester Supplementary Theory
              Examinations, Dec-2023
            </a>
          </li>
          <li>
            <a href={dsdpharm2ndsemdec2023}>
              Date sheet of D.Pharm. 2nd year Supplementary Examination,
              December-2023
            </a>
          </li>
          <li>
            <a href={datesheetbpt7thsemdec2023}>
              Date Sheet of BPT 7th Semester Supplementary Theory Examination,
              Dec-2023
            </a>
          </li>
          <li>
            <a href={datesheetDpharm1styeardec2023}>
              Date sheet of D.Pharm. 1st year Supplementary Examination,
              December-2023
            </a>
          </li>
          <li>
            <a href={datesheetofmbapm3rdsemexamdec2023}>
              DateSheet of MBA-PM 3rd Semester Supplementary Examination,
              Dec-2023
            </a>
          </li>
          <li>
            <a href={newdata0009}>
              Date sheet of DMLT 4th Semester (Internship Report Evaluation)
              Examination, October -2023
            </a>
          </li>

          <li>
            <a href={newdata0008}>
              Datesheet of M.pharm 2nd sem Practical Examination DIPSAR, October
              2023
            </a>
          </li>

          <li>
            <a href={newdata0006}>
              Practical Date Sheet of B.Pharm (Ayurveda) 2nd Semester
              Examination, October-2023
            </a>
          </li>
          <li>
            <a href={newdata0007}>
              Practical Date Sheet of B.Pharm (Ayurveda) 4th Semester
              Examination, October-2023
            </a>
          </li>

          <li>
            <a href={newdata0002}>
              Revised DateSheet of DMYS 2nd and 3rd Trimester Batch 1st and 2nd
              Supple. Examination, October-2023
            </a>
          </li>

          <li>
            <a href={newdata0005}>
              Date Sheet of M. Sc. Sports Science 2nd & 4th Semester Practical
              Examinations, Oct-2023
            </a>
          </li>

          <li>
            <a href={newdata0004}>
              Datesheet of M.pharm 2nd sem Practical Exams DIPSAR, October 2023
            </a>
          </li>

          <li>
            <a href={newdata0003}>
              Datesheet of M.Pharm 2nd Semester Practical Examination (SPS),
              October 2023
            </a>
          </li>

          <li>
            <a href={newdata0001}>
              Datesheet of M.Pharm 2nd Semester Practical Examination (SPS),
              October 2023
            </a>
          </li>

          <li>
            <a href={dateSheetNewData001}>
              Date Sheet of BPT 2nd & 4th Semester Practical Examination,
              Oct-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData002}>
              Date sheet of Pre-Ph.D Practical (Supplementary) examination,
              October -2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData003}>
              Date sheet pre-PhD supplementary Examination, October -2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData004}>
              Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary,
              Pediatrics) 2nd Semester Practical Examinations, Oct-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData005}>
              Date Sheet of MPTS 4th Semester Practical Examinations, Oct-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData006}>
              Datesheet of M.Pharm 2nd Semester Theory Examination (SPS),
              October 2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData007}>
              Datesheet of M.Pharm 2nd Semester Theory Examination (DIPSAR),
              October 2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData008}>
              Date Sheet of B.Sc. (Hons.) Sports Science 2nd semester practical
              examinations, October-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData009}>
              Date Sheet of B.Pharm (Ayurveda) 4th Semester Examination,
              October-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData010}>
              Date Sheet of B. Pharm (Ayurveda) 2nd Semester Examination,
              October-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData011}>
              Practical Date Sheet of M.Sc. AIM 2nd Semester Examination,
              October-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData012}>
              Practical Date Sheet of MBA-PM, MBA-HHM &amp; MPH 2nd Semester
              Examination, October-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData013}>
              Date Sheet of BPT 2nd &amp; 4th Semester Examinations, Oct-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData014}>
              Datesheet of B.Pharm 2nd Semester Practical Examination (SPS),
              September 2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData015}>
              Datesheet of B.Pharm 4th Semester Practical Examination (SPS),
              September 2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData016}>
              Date Sheet of B. Pharm 2nd Semester Practical Examination
              (DIPSAR), September 2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData017}>
              Date Sheet of B. Pharm 4th Semester Practical Examination
              (DIPSAR), September 2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData018}>
              Date Sheet of DMLT &amp; BMLT 2nd Semester Main and Supplementary
              ( Practical ) Examination, September-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData019}>
              Date Sheet of DMLT &amp; BMLT 4th Semester (Practical)
              Examination, September-2023
            </a>
          </li>
          <li>
            <a href={dateSheetNewData020}>
              Date Sheet of DMLT &amp; BMLT 2nd Semester Main and Supplementary
              ( Practical ) Examination, September-2023
            </a>
          </li>

          <li>
            <a
              href={
                DateSheetofBScHonsSportsScience2ndSemesterExaminationsSep2023pdf
              }
            >
              Date Sheet of B. Sc. (Hons.) Sports Science 2nd Semester
              Examinations, Sep-2023
            </a>
          </li>
          <li>
            <a
              href={DateSheetofMScSportsScience2nd4thSemesterExaminationSep2023}
            >
              Date Sheet of M. Sc. Sports Science 2nd 4th Semester Examination,
              Sep-2023
            </a>
          </li>
          <li>
            <a
              href={
                PracticalDateSheetofBScHonsBiomedicalScience2ndSemesterExaminationSeptember2023
              }
            >
              Practical Date Sheet of B.Sc. (Hons.) Biomedical Science 2nd
              Semester Examination, September-2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofMBAPM2ndSemesterSupplementaryExaminationSeptember2023
              }
            >
              Date Sheet of MBA-PM 2nd Semester Supplementary Examination,
              September-2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofMBAPET2ndSemesterSupplementaryExaminationSeptember2023
              }
            >
              Date Sheet of MBA-PET 2nd Semester Supplementary Examination-
              September 2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofMScArtificialIntelligenceinMedicine2ndSemesterTheoryExaminationSeptember2023
              }
            >
              Date Sheet of M.Sc. Artificial Intelligence in Medicine 2nd
              Semester (Theory) Examination, September-2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHonsSportsScience4thSemesterPracticalExaminationSep2023
              }
            >
              Date Sheet of B. Sc. (Hons.) Sports Science 4th Semester Practical
              Examination, Sep-2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofMBAITMMBAPMMBAHHMMPH2NDSemesterExaminationSeptember2023
              }
            >
              Date sheet of MBA-ITM, MBA-PM, MBA-HHM, MPH 2ND Semester
              Examination, September-2023
            </a>
          </li>
          <li>
            <a href={PracticalDatesheetofBpharm6thsemesterDIPSARSeptember2023}>
              Practical Date sheet of B. pharm 6th semester (DIPSAR)
              September,2023
            </a>
          </li>
          <li>
            <a href={DatesheetofDMLTBMLT4thSemesterExaminationSeptember2023}>
              Date sheet of DMLT BMLT 4th Semester Examination, September-2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofDMLTBMLT2ndSemesterSupplementaryOldSchemeExaminationSept2023
              }
            >
              Date Sheet of DMLT BMLT 2nd Semester Supplementary (Old Scheme)
              Examination, Sept-2023
            </a>
          </li>
          <li>
            <a href={DatesheetofDMLTBMLT2ndSemesterExaminationSeptember20232}>
              Date sheet of DMLT BMLT 2nd Semester Examination September -2023
            </a>
          </li>
          <li>
            <a
              href={
                DATESHEETOFBPHARM6THSEMESTERPRACTICALEXAMINATIONSPSSEPTEMBER2023
              }
            >
              DATESHEET OF B.PHARM 6TH SEMESTER PRACTICAL EXAMINATION (SPS),
              SEPTEMBER 2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHonsBiomedicalScience2ndSemesterTheoryExaminationSeptember2023
              }
            >
              Date Sheet of B.Sc. (Hons.) Biomedical Science 2nd Semester Theory
              Examination, September-2023
            </a>
          </li>
          <li>
            <a href={CORRIGENDUMOFBPHARM2NDSEMESTEREXAMINATION20233}>
              CORRIGENDUM OF B.PHARM 2ND SEMESTER EXAMINATION, 2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofMpharm2ndsemesterSuppleTheoryExaminationSPSAugust2023
              }
            >
              Datasheet of M. Pharm 2nd semester Supple. Theory Examination
              (SPS) August 2023
            </a>
          </li>
          <li>
            <a href={DatesheetBBACH2ndSemesterSupplySept2023}>
              Date sheet BBA-CH 2nd Semester Supply Sept-2023
            </a>
          </li>
          <li>
            <a href={DatesheetBBAHCBBACH2nd4thSemestersept2023}>
              Date sheet BBA-HC,BBA-CH, 2nd 4th Semester sept-2023
            </a>
          </li>
          <li>
            <a href={DatesheetofBPharm6thSemesterTheoryExaminationAugust20231}>
              Date Sheet of B. Pharm 6th Semester Theory Examination, August
              2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHonsSportsScience4thSemesterExaminationAug2023
              }
            >
              Date Sheet of B. Sc. (Hons.) Sports Science 4th Semester
              Examination, Aug-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPT6th8thSemesterPracticalExaminationJuly2023}>
              Date Sheet of BPT 6th 8th Semester Practical Examination,
              July-2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHonsSportsScience6thSemesterExaminationJuly2023
              }
            >
              Date Sheet of B. Sc. (Hons.) Sports Science 6th Semester
              Examination, July-2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofDpharm1styearAnnualSupplementaryPracticalJuly202311
              }
            >
              Date sheet of D. Pharm. 1st year Annual Supplementary (Practical)
              July-2023
            </a>
          </li>
          <li>
            <a
              href={
                RevisedDatesheetofBPharm8thSemProjectVivaVoceExaminationJuly2023SPS13ba7f19923da2c078ef
              }
            >
              BPT B.Sc. (Hons.) Sports Science Exam Rescheduled Dates
            </a>
          </li>
          <li>
            <a
              href={
                RevisedDatesheetofBPharm8thSemProjectVivaVoceExaminationJuly2023SPS
              }
            >
              Revised Date Sheet of B. Pharm 8th Sem (Project Viva Voce)
              Examination July-2023 SPS
            </a>
          </li>
          <li>
            <a
              href={
                RescheduledDateSheetofDPharm1stYearAnnualSupplExaminationJuly20231
              }
            >
              Rescheduled Date Sheet of D. Pharm. 1st Year Annual Suppl.
              Examination, July-2023
            </a>
          </li>
          <li>
            <a href={RescheduledDateSheetofDPharm2ndYearPracticalJuly2023}>
              Rescheduled Date Sheet of D. Pharm. 2nd Year Practical July-2023
            </a>
          </li>
          <li>
            <a
              href={
                RESHEDULEDDATESHEETOFBPHARM8THSEMPROJECTVIVAVOCEEXAMDIPSARJULY2023
              }
            >
              RESHEDULED DATESHEET OF B.PHARM 8TH SEM PROJECT VIVA VOCE EXAM.
              (DIPSAR), JULY 2023
            </a>
          </li>
          <li>
            <a
              href={
                DATESHEETOFBPHARM8THSEMESTERPROJECTVIVAVOCEEXAMINATIONSPSJULY2023
              }
            >
              DATESHEET OF B.PHARM 8TH SEMESTER PROJECT VIVA VOCE EXAMINATION
              (SPS), JULY 2023
            </a>
          </li>
          <li>
            <a href={DATESHEETOFBPHARM8THSEMESTERPROJECTVIVAVOCEDIPSARJULY2023}>
              DATESHEET OF B.PHARM 8TH SEMESTER PROJECT VIVA VOCE (DIPSAR), JULY
              2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofDPharm2ndyearPracticalOldSchemeExaminationJuly2023
              }
            >
              Date sheet of D. Pharm. 2nd year Practical (Old Scheme)
              Examination, July-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofDpharm2ndyearPracticalExaminationJuly2023}>
              Date Sheet of D. Pharm. 2nd year Practical Examination, July-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPT6thSemesterTheoryExamiantionJuly2023}>
              Date Sheet of BPT 6th Semester Theory Examination, July-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPT8thSemesterTheoryExamiantionJuly2023}>
              Date Sheet of BPT 8th Semester Theory Examination, July-2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHonsSportsScience6thSemesterTheoryExaminationJuly2023
              }
            >
              Date Sheet of B. Sc. (Hons.) Sports Science 6th Semester Theory
              Examination, July-2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofDpharm1stYearAnnualSupplementaryExaminationJuly2023
              }
            >
              Date Sheet of D. Pharm. 1st Year Annual Supplementary Examination,
              July-2023
            </a>
          </li>
          <li>
            <a href={DatesheetofBPharm8thSemesterTheoryExaminationJune2023}>
              Date Sheet of B. Pharm 8th Semester Theory Examination , June 2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofDPharm2ndYearAnnualSupplementaryExaminationJune20231
              }
            >
              Date Sheet of D. Pharm. 2nd Year Annual Supplementary Examination,
              June-2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofMScSportsScience1st3rdSemesterPracticalExaminationMay202311
              }
              target="_blank"
              rel="noreferrer"
            >
              Date sheet of M.Sc. Sports Science 1st 3rd Semester Practical
              Examination, May-2023
            </a>
          </li>
          <li>
            <a
              href={
                PracticalDatesheetofMPharm1stSemesterPracticalExaminationDRAExeDRSBranchSPSMay2023
              }
            >
              Practical Date sheet of M. Pharm 1st Semester Practical
              Examination (DRA Exe- DRS Branch) SPS, May 2023
            </a>
          </li>
          <li>
            <a
              href={
                PracticalDatesheetofBPharm2ndSemesterSupplePracticalExaminationDIPSARMay2023
              }
            >
              Practical Date sheet of B. Pharm 2nd Semester Supple. Practical
              Examination DIPSAR, May 2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBScHons1stSemesterPracticalExaminationMay2023}>
              Date Sheet of B. Sc. (Hons.) Sports Science 1st semester practical
              Examination, May -2023
            </a>
          </li>
          <li>
            <a
              href={DatesheetofMPharm1stSemesterPracticalExaminationSPSMay20231}
            >
              Date Sheet of M. Pharm 1st Semester Practical Examination SPS, May
              2023
            </a>
          </li>
          <li>
            <a href={DateSheetBPharmAyurveda1stSemesterpracticalExamination1}>
              Date Sheet of B. Pharm (Ayurveda) 1st Semester Practical
              Examination, May-2023
            </a>
          </li>
          <li>
            <a href={DateSheetBPharmAyurveda3rdSemesterPracticalExam}>
              Date Sheet of B. Pharm (Ayurveda) 3rd Semester Practical
              Examination, May-2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBPT4th6th8thSemesterSupplementaryPracticalExaminationMay2023
              }
            >
              Date Sheet of BPT 4th, 6th 8th semester supplementary practical
              Examination, May -2023
            </a>
          </li>
          <li>
            <a href={DateSheetofMScAIM1stSemesterPracticalExaminationMay2023}>
              Date Sheet of M.Sc. AIM 1st Semester Practical Examination,
              May-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofMScAIPM3rdSemesterPracticalExaminationMay2023}>
              Date Sheet of M.Sc. AIPM 3rd Semester Practical Examination, May-
              2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofMPharm1stSemesterPracticalExaminationDIPSARMay2023
              }
            >
              Date sheet of M. Pharm 1st Semester Practical Examination DIPSAR,
              May 2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofBPharm6thSemesterTheorySuppleExaminationDIPSARDPSRUApril2023
              }
            >
              Date sheet of B. Pharm 6th Semester Theory Supple. Examination
              (DIPSAR DPSRU), April 2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofBPharm4thSemesterTheorySuppleExaminationDIPSARDPSRUApril2023
              }
            >
              Date sheet of B. Pharm 4th Semester Theory Supple. Examination
              (DIPSAR DPSRU), April 2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofBPharm2ndSemesterTheorySuppleExaminationDIPSARDPSRUApril2023
              }
            >
              Date sheet of B. Pharm 2nd Semester Theory Supple. Examination
              (DIPSAR DPSRU), April 2023
            </a>
          </li>
          <li>Date sheet of M. Pharm 3rd Semester Examination SPS, May 2023</li>
          <li>
            <a
              href={
                DatesheetofMPharm1stSemesterTheoryExaminationDRAExeDRSSPSApril2023
              }
            >
              Date sheet of M. Pharm 1st Semester Theory Examination DRA Exe.
              DRS (SPS), April 2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofBPharm3rdSemesterPracticalExaminationSPSApril2023
              }
            >
              Date sheet of B. Pharm 3rd Semester Practical Examination SPS,
              April 2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofBPT2nd4thsemestersuppleTheoryExaminationApril2023
              }
            >
              Date sheet of BPT 2nd 4th semester supple. Theory Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={DatesheetofBPT6thsemestersuppleTheoryExaminationApril2023}>
              Date sheet of BPT 6th semester supple. Theory Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={DatesheetofMPharm3rdSemesterExaminationDIPSARApril2023}>
              Date sheet of M. Pharm 3rd Semester Examination DIPSAR, April 2023
            </a>
          </li>
          <li>
            <a href={DatesheetofBPharm3rdSemesterExaminationDIPSARApril2023}>
              Date sheet of B. Pharm 3rd Semester Examination DIPSAR, April 2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofDMLTBMLT1stSemesterPracticalexaminationApril20231
              }
            >
              Date Sheet of DMLT BMLT 1st Semester (Practical) Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofMScAIPM3rdSemesterTheoryExaminationApril2023}>
              Date Sheet of M.Sc. AIPM 3rd Semester (Theory) Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofMScAIM1stSemesterTheoryExaminationMay2023}>
              Date Sheet of M.Sc. AIM 1st Semester (Theory) Examination,
              May-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofMPT1stSemesterPracticalExaminationApril2023}>
              Date Sheet of MPT (Sports Medicine, Cardiovascular and Pulmonary,
              Pediatrics) 1st Semester Practical Examination, April -2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofMPTSportsMedicine3rdSemesterPracticalExaminationApril2023
              }
            >
              Date Sheet of MPT (Sports Medicine ) 3rd Semester Practical
              Examination, April -2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHonsSportsScience1stSemesterTheoryExaminationApril2023
              }
            >
              Date Sheet of B. Sc. (Hons.) Sports Science 1st Semester Theory
              Examination, April-2023.
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofMScSportsScience1st3rdSemesterExaminationApril202
              }
            >
              Date Sheet of M. Sc. Sports Science 1st 3rd Semester Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPharmayurveda1stSemTheoryExaminationApril2023}>
              Date Sheet of B. Pharm (Ayurveda) 1st Semester Theory Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPharmayurveda3stSemTheoryExaminationApril2023}>
              Date Sheet of B. Pharm Ayurveda 3rd Sem Theory Exam, April-2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofBPharm8thSemesterTheorySupplementaryExaminationSPSAPRIL2023
              }
            >
              Date Sheet of B. Pharm 8th Semester Supplementary Theory
              Examination SPS, April 2023
            </a>
          </li>
          <li>
            <a
              href={DatesheetofMPharm1stSemesterTheoryExaminationSPSApril20232}
            >
              Date Sheet of M. Pharm 1st Semester Theory Examination SPS, April
              2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofMPharm1stSemesterTheoryExaminationDIPSARApril2023
              }
            >
              Date Sheet of M. Pharm 1st Semester Theory Examination DIPSAR,
              April 2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPT1st3rdSemesterPracticalExaminationApril2023}>
              Date Sheet of BPT 1st 3rd Semester Practical Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={PracticalDateSheetofBScBiomedical1stSemesterApril2023}>
              Date sheet of B.Sc. Biomedical Sciences 1st Semester (Practical)
              Examination, April – 2023
            </a>
          </li>
          <li>
            <a
              href={
                PracticalDatesheetofBPharm5thSemesterExaminationSPSMarch2023
              }
            >
              Practical Examination Date sheet of B. Pharm 5th Semester SPS,
              March 2023
            </a>
          </li>
          <li>
            <a href={MBAPET1stSemesterSupplementarytheoryExamMarch2023}>
              Date Sheet of MBA-PET 1st Semester Supplementary Examination,
              March-2023
            </a>
          </li>
          <li>
            <a
              href={
                RevisedDatesheetofBPharm3rdSemesterTheoryExaminationApril2023
              }
            >
              Revised Date Sheet of B. Pharm 3rd Semester Theory Examination
              (DIPSAR and SPS), April-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofMPTSM3rdSemesterExaminationMarch2023}>
              Date Sheet of MPT (Sports Medicine) 3rd Semester Examination,
              March-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofMPT1stSemesterExaminationMarch2023}>
              Date Sheet of MPT 1st Semester Examination, March-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofDMLTBMLT1stSemApril2023}>
              Date Sheet of DMLT BMLT 1st Semester Theory Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofDMLTBMLT1stSemSupplementaryApril2023}>
              Date Sheet of DMLT BMLT 1st Semester Supplementary Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={CamScanner0317202313031}>
              Date Sheet of B.Sc. (Biomedical Sciences) 1st Semester Theory
              Examination, March-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofMBAPMITMHHMMPH1stSemTheoryExaminations}>
              Date Sheet of MBA-PM, MBA-ITM, MBA-HHM MPH 1st Sem Theory
              Examination, March-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBBAHC1stSemesterexaminationMarch2023}>
              Date Sheet of BBA-HC 1st Semester Theory Examination, March-2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBBACH1stSemesterSupplementaryexaminationMarch2023new
              }
            >
              Date Sheet of BBA-CH 1st Semester Supplementary Examination,
              March-2023
            </a>
          </li>
          <li>
            <a href={PracticalDatesheetBMLT3rdSemesterMarch2023}>
              Date sheet of BMLT 3rd Semester Practical Examination, March-2023
            </a>
          </li>
          <li>
            <a href={practicalDatesheetDMLT3rdSemesterMarch2023}>
              Date sheet of DMLT 3rd Semester Practical Examination, March-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPT3rdSemesterTheoryExaminationMarhc2023}>
              Date Sheet of BPT 3rd Semester Theory Examination, March-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPT1stSemesterTheoryExaminationMarch20231}>
              Date Sheet of BPT 1st Semester Theory Examination, March-2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofBScHonsSportsScience3rdSemesterpracticalexaminationMarch202311
              }
            >
              Date Sheet of B.Sc. (Hons.) Sports Science 3rd Semester Practical
              Examination, March-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofPrePhDPracticalExaminationsMarch20231}>
              Date Sheet of Pre-PhD Practical Examination, March-2023
            </a>
          </li>
          <li>
            <a href={AdobeScan13Mar2023}>
              Date Sheet of MBA-PM, MBA-PET, MHA MPH 3rd Semester Theory
              Examination, March-2023
            </a>
          </li>
          <li>
            <a href={BMLTDMLT3rdSEMTheoryExamDateSheet}>
              Date Sheet of BMLT DMLT 3rd Semester Theory Examination,
              March-2023
            </a>
          </li>
          <li>
            <a href={BBA3rdSemTheoryxamDateSheet}>
              Date Sheet of BBA-CH 3rd Semester Theory Examination, March-2023
            </a>
          </li>
          <li>
            <a href={DatesheetofBPharm1stSemesterTheoryExaminationMarch2023}>
              Date Sheet of B. Pharm 1st Semester Theory Examination (DIPSAR and
              SPS), March-2023
            </a>
          </li>
          <li>
            <a href={DatesheetofBPharm5thSemesterTheoryExaminationMarch2023}>
              Date Sheet of B. Pharm 5th Semester Theory Examination (DIPSAR and
              SPS), March-2023
            </a>
          </li>
          <li>
            <a href={BPharm7thSemesterPracticalDatesheetSPSFebruary2023}>
              Date Sheet of B.Pharm 7th Semester Practical Examination SPS,
              February – 2023
            </a>
          </li>
          <li>
            <a href={BPharm7thSemesterPracticalDatesheetDIPSARFebruary2023}>
              Date Sheet of B.Pharm 7th Semester Practical Examination DIPSAR,
              February – 2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHonsSportsSciences5thSemesterPracticalExaminationFeb2023
              }
            >
              Date Sheet of B. Sc. (Hons.) Sports Sciences 5th Semester
              Practical Examination, February- 2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofBPharm5thSemesterSupplePracticalExaminationSPSFebruary2023
              }
            >
              Date Sheet of B. Pharm 5th Semester Supplementary Practical
              Examination(SPS), February 2023
            </a>
          </li>
          <li>
            <a
              href={
                RevisedDatesheetofBPharm7thSemesterTheoryExaminationFebruary2023
              }
            >
              Revised Date Sheet of B. Pharm 7th Semester Theory Examination,
              February 2023
            </a>
          </li>
          <li>
            <a href={DateSheetofPrePhDexaminationFeb20231}>
              Date Sheet of Pre-PhD Examination, February-2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofDPharm1styearpracticalexaminationsupplementaryFeb2023
              }
            >
              Date Sheet of D. Pharm 1st Year Practical Supplementary
              Examination, February-2023
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofBPharm1stSemesterSupplePracticalExaminationFebruary2023
              }
            >
              Date Sheet of B.Pharm 1st Semester Supple. Practical Examination,
              February 2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBPT5th7thSemesterPracticalExaminationFebruary2023
              }
            >
              Date Sheet of BPT 5th 7th Semester Practical Examination,
              February- 2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHonsSportsScience3rdSemesterTheoryExaminationFeb2023
              }
            >
              Date Sheet of B. Sc. (Hons.) Sports Science 3rd Semester
              Examination, February- 2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHonsSportsScience5thSemesterTheoryExaminationFeb20231
              }
            >
              Date Sheet of B. Sc. (Hons.) Sports Science 5th Semester
              Examination, February- 2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofSAYAMMCWCSC2ndSemesterPracticalExaminationFeb20231
              }
            >
              Date Sheet of SAYA MMC (WCSC) 2nd Semester Practical Examination,
              February- 2023
            </a>
          </li>
          <li>
            <a
              href={
                RevisedDatesheetofBPharm5thSemesterSupplementaryTheoryExaminationJanuary2023
              }
            >
              Revised Date sheet of B. Pharm. 5th Semester Supplementary
              (Theory) Examination, January- 2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPharm1stSemesterSuppleExaminationJanuary2023}>
              Date Sheet of B.Pharm 1st Semester Supplementary Theory
              Examination, January 2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPharm3rdSemesterSuppleExaminationJanuary2023}>
              Date Sheet of B.Pharm 3rd Semester Supplementary Theory
              Examination, January 2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPT7thSemester}>
              Date Sheet of BPT 7th Semester Examination, January-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPT5thSemester}>
              Date Sheet of BPT 5th Semester Examination, January-2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPT3rdsemestersupplementaryExamsJan2023}>
              Date Sheet of BPT 3rd Semester Supplementary Examination, January-
              2023
            </a>
          </li>
          <li>
            <a href={DateSheetofBPT1stsemestersupplementaryExamsJan20231}>
              Date Sheet of BPT 1st Semester Supplementary Examination, January-
              2023
            </a>
          </li>
          <li>
            <a href={DateSheetofWCSC2ndsemesterExamsJan2023}>
              Date Sheet of SAYA MMC (WCSC) 2nd Semester Theory Examination,
              January- 2023
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofMPharm2ndSemesterPracticalExaminationDIPSARNovember2022
              }
            >
              Date Sheet of M.Pharm 2nd Semester Practical Examination, DIPSAR ,
              November 2022
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofMScSportsScience2ndSemesterPracticalExaminationNovember2022
              }
            >
              Date Sheet of M. Sc. Sports Science 2nd Semester Practical
              Examination, November- 2022
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofMPharm2ndSemesterDRAPracticalExaminationSPSNovember2022
              }
            >
              Date Sheet of M.Pharm 2nd Semester Practical Examination Drug
              Regulatory Affairs (SPS), November 2022
            </a>
          </li>
          <li>
            <a
              href={
                DatesheetofMPharm2ndSemesterPracticalExaminationExecutiveDrugRegulatoryScienceSPSNovember2022
              }
            >
              Datesheet of M. Pharm 2nd Semester Practical Examination Executive
              Drug Regulatory Science (SPS), November 2022.
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBPharm2ndSemesterPracticalExaminationSPSOctober2022
              }
            >
              Date Sheet of B.Pharm 2nd Semester Practical Examination SPS,
              October 2022
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBPharm2ndSemesterPracticalExaminationDIPSAROctober2022
              }
            >
              Date Sheet of B.Pharm 2nd Semester Practical Examination DIPSAR,
              October 2022
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofMPTSportsMedicine2ndsemesterpracticalExaminationNovember2022
              }
            >
              Date Sheet of MPT (Sports Medicine) 2nd Semester Practical
              Examination, November-2022
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHonsSportsScience2ndsemesterPracticalExaminationOctober2022
              }
            >
              Date Sheet of B. Sc. (Hons.) Sports Science 2nd Semester Practical
              Examination, October-2022
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofMScSportsScience2ndSemesterTheoryExaminationOctober2022
              }
            >
              Date Sheet of M. Sc. Sports Sciences 2nd Semester Theory
              Examination, October- 2022
            </a>
          </li>

          <li>
            <a
              href={
                DateSheetofBPharm4thSemesterTheoryExaminationforLateralEntryStudentsofDIPSARSPSOctober2022
              }
            >
              Date Sheet of B.Pharm 4th Semester Theory Examination for Lateral
              Entry Students of (DIPSAR SPS), October 2022
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofBScHSportsSciences4thSemesterSupplementaryPracticalExaminationSeptember2022
              }
            >
              Date Sheet of B. Sc. (H.) Sports Sciences 4th Semester
              Supplementary Practical Examination, September-2022
            </a>
          </li>
          <li>
            <a
              href={
                DateSheetofDPharm2ndYearSupplementaryPracticalExaminationAugust2022
              }
            >
              Date Sheet of D. Pharm 2nd Year Supplementary Practical
              Examination, August 2022
            </a>
          </li>
          <li>
            <a
              href={
                RevisedDateSheetofBPharm7thSemesterSupplePracticalExamAugust2022
              }
            >
              Revised Date Sheet of B.Pharm 7th Semester Supplementary Practical
              Examination, August – 2022
            </a>
          </li>
          <li>
            <a
              href={
                RevisedDateSheetofBPharm7thSemesterSuppleTheoryExaminationAugust2022
              }
            >
              Revised Date Sheet of B.Pharm 7th Semester Supplementary Theory
              Examination, August 2022
            </a>
          </li>
          <li>
            <a
              href={DatesheetofDPharm2ndYearSupplementaryExaminationAugust2022}
            >
              Date sheet of D. Pharm 2nd Year Supplementary Examination, August
              2022
            </a>
          </li>
        </ul>
      </div>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Examination Date Sheet
      </h2>

      <div className="nirf-data-container">
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/examination/examination-datesheet"
            className="nirf-botton library-navlink library-navlink active"
          >
            2023
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/examination/date-sheet-2022"
            className="nirf-botton library-navlink library-navlink active"
          >
            2022
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/examination/date-sheet-2021"
            className="nirf-botton library-navlink library-navlink active"
          >
            2021
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link className="nirf-botton library-navlink library-navlink active">
            2020
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/examination/date-sheet-2019"
            className="nirf-botton library-navlink library-navlink active"
          >
            2019
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/examination/date-sheet-2018"
            className="nirf-botton library-navlink library-navlink active"
          >
            2018
          </Link>
        </section>
      </div>
    </div>
  );
};

export default DateSheet;
