import React from 'react'
import bb1 from './bb1.jpg'
import bb2 from './bb2.jpg'
import bb3 from './bb3.jpg'
import bb4 from './bb4.jpg'
import bb5 from './bb5.jpg'
import bb6 from './bb6.jpg'
   
const BusinessBlaster = () => {
  return (
    <div className='dpsru-every-component-css'>
    <h2 className='g20-maha-food-festival-heading'>DPSRU Proudly Participated in Business Blaster Schemes (Seniors)
    launch event at IIIT -Delhi</h2>
    <div className="g20-maha-food-festival-paragrauph">
    Delhi Pharmaceutical Sciences and Research University (DPSRU) is proud to have been a part of
the launch event for the Business Blaster Scheme (Seniors), aimed at students from GNCTD
Universities and ITIs. The scheme is designed to foster innovative ideas and nurture
entrepreneurial skills among young talent, especially students from various universities.
 <br /> <br />

 A group of 43 DPSRU students, accompanied by faculty members Dr. Akash Midha and Dr.
Jitender Munjal attended the launch event on 10th December 2024 at IIIT-Delhi under the
leadership of Honorable Vice Chancellor Prof. Ravichandiran V. Before departing for the event,
Prof. Ravichandiran motivated and inspired the students with insights on upcoming innovations
in healthcare and allied fields. He has also given his blessings and best wishes to the real
stakeholders that are students. <br /> <br />

He emphasized the current era of multidisciplinary research, stressing that research should not
remain confined to laboratories but should be geared towards commercialization for the greater
societal good. He further highlighted the University’s Incubation and Innovation Center, which is
continuously working to cultivate a strong startup culture, and shared success stories of the many
startups that have emerged from this initiative. <br /> <br />
 
Prof. Ravichandiran encouraged the students to actively develop new skills during the event and
to consider transforming their ideas into innovative, sustainable products or services. He
emphasized that the University is and will continue to provide conducive entrepreneurship eco
system to support students in their entrepreneurial journey. <br /> <br />

At the launch event, Ms. Atishi, the Honorable Chief Minister of Delhi, addressed the audience
and highlighted the importance of empowering students to become job creators. She shared
success stories from the school-level Business Blasters initiative, noting how the program had
helped eliminate the fear of failure, making students confident risk-takers who are ready to
contribute to India's economy. <br /><br />
Ms. Atishi also discussed how small seed funds had been transformed into successful ventures,
and how this initiative is a significant step toward making India a global leader in
entrepreneurship and innovation. The event progressed with an engaging Panel Discussion
featuring successful young entrepreneurs who shared their invaluable insights on developing an
entrepreneurial mindset. <br /><br />
To further boost the entrepreneurial spirit, Mr. Mukul, the founder of ScrapUncle: The Online
Kabaadiwala, shared his major learnings from his entrepreneurial journey. One of his key
takeaways was the importance of taking the first step by launching a Minimum Viable Product
(MVP). He emphasized that an MVP serves as a starting point for growth, with future expansion
driven by the inflow of funds and new ideas. <br /><br />
The session was not only a source of inspiration but also a reminder of the potential that lies in
taking initiative, being bold in the face of uncertainty, and continuously evolving ideas into
impactful ventures. The young entrepreneurs' success stories reinforced the idea that the right
support and a proactive mindset can turn any idea into a thriving business. <br /><br />
After the event, DPSRU students shared their thoughts, expressing that it was a fantastic
experience to hear directly from the experts. The session was truly inspiring and served as an
eye-opener, encouraging us to think beyond conventional boundaries and expand our
perspectives. It motivated us to approach challenges with innovative solutions and think outside
the box.  <br />
    </div>

    <h2 className="dpsru-secondary-heading-for-every-page">
      GALLERY
    </h2>

    <div className="workshop-gallery-container">
      <img src={bb1} alt="Business Blaster" />
      <img src={bb2} alt="Business Blaster" />
      <img src={bb3} alt="Business Blaster" />
      <img src={bb4} alt="Business Blaster" />
      <img src={bb5} alt="Business Blaster" />
      <img src={bb6} alt="Business Blaster" />
    </div>
    </div>
  )
}

export default BusinessBlaster
