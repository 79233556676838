import React from "react";
import newgif from '../../NEW/new.gif'

// Hostel
import h1 from "../HostelData/Hostel-circular07jan2021.pdf";
import h2 from "../HostelData/girl-hostel-due-list2021.pdf";
import h3 from "../HostelData/Boyshostel-due-list2021.pdf";
import h4 from "../HostelData/medical-certificate.docx";
import h5 from "../HostelData/leave-application.docx";
import h6 from "../HostelData/Hostel-rules30-Dec-2019_madhu.docx";
import h7 from "../HostelData/hostel-id-card.doc";
import h8 from "../HostelData/Hostel-Admission-Form.docx";
import h9 from "../HostelData/Gate-pass.docx";
import h10 from "../HostelData/Declaration-by-the-students.docx";
import h11 from "../HostelData/ANTI-RAGGING-AFFIDAVIT.doc";
import h12 from "../HostelData/Notice for pay hostel and mess fees before 4_11_2023.pdf"
import HostalAccommodationduringsessionfy2324 from '../StudentsData/Hostel Accommodation during session FY 2023-24.pdf'
import girlshostalAccommodationduringsessionFY2324 from '../StudentsData/Girls hotel Accommodation during session FY 2023-24.pdf'
import extensionstaygirlshostelfeesandmesssss from '../HostelData/Extension (stay) Girls hostel fees and mess fees upto June 2024.pdf'
import boysHostelaccomdationfinallistfy2324 from '../HostelData/Boys hotel Accommodation final list FY 2023-24.pdf'
import extemsionstayboyshostelfeesandmessfees from '../HostelData/Extension (stay) Boys hostel fees and mess fees upto June 2024.pdf'
import thirdListofBOyshotelaccommodationfy2425  from '../HostelData/3rd List of Boys hotel Accommodation FY 2024-25.pdf'
import FourththListofBoysHostel from '../HostelData/4th list of Boys hostel.pdf'
import FourththListofGirlssHostel from '../HostelData/Girls hostel fy24.pdf'
import finallistofboysHostel from '../HostelData/Final list of Boys Hostel.pdf'
import listofGirlshostalaccfy2425 from '../HostelData/4th list of Girls Hostel Accommodation F.Y. 2024-25.pdf'
import messpriceListboysHostal2025 from '../HostelData/Hostel Mess fees from January 2025 to June 2025 boys hostel.pdf'
import messpriceListGirlsHostal2025 from '../HostelData/Hostel Mess fees from January 2025 to June 2025 Girls hostel.pdf'
import HostelsecurityRefundclaimnotice from '../HostelData/Hostel Security Refund Claim Notice.pdf'


const StudentHostal = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">Hostel</h2>

      {/* Hostel */}
      <div className="inner-container">
        <ul>
          {" "}
          <li>
            <a href={HostelsecurityRefundclaimnotice} target="_blank"  rel="noreferrer">
            Hostel Security Refund Claim Notice
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={messpriceListGirlsHostal2025} target="_blank"  rel="noreferrer">
            Hostel Mess fees from January 2025 to June 2025 (Girls hostel)
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={messpriceListboysHostal2025} target="_blank"  rel="noreferrer">
            Hostel Mess fees from January 2025 to June 2025 (Boys hostel)
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={listofGirlshostalaccfy2425} target="_blank"  rel="noreferrer">
            4th list of Girls Hostel Accommodation F.Y. 2024-25
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={finallistofboysHostel} target="_blank"  rel="noreferrer">
            Final list of Boys Hostel
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={FourththListofGirlssHostel} target="_blank"  rel="noreferrer">
            Girls Hostel Accommodation notice
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={FourththListofBoysHostel} target="_blank"  rel="noreferrer">
            4th List of Boys hostel Accommodation FY 2024-25
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={thirdListofBOyshotelaccommodationfy2425} target="_blank"  rel="noreferrer">
            3rd List of Boys hostel Accommodation FY 2024-25
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={extemsionstayboyshostelfeesandmessfees} target="_blank"  rel="noreferrer">
            Extension (stay) Boys hostel fees and mess fees upto June 2024
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={boysHostelaccomdationfinallistfy2324} target="_blank"  rel="noreferrer">
            Boys hostel Accommodation final list FY 2023-24
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={extensionstaygirlshostelfeesandmesssss} target="_blank"  rel="noreferrer">
            Extension (stay) Girls hostel fees and mess fees upto June 2024
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={girlshostalAccommodationduringsessionFY2324} target="_blank"  rel="noreferrer">
            Girls hostel Accommodation during session FY 2023-24
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={HostalAccommodationduringsessionfy2324} target="_blank"  rel="noreferrer">
            Hostel Accommodation during session FY 2023-24
            </a> <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={h12} target="_blank"  rel="noreferrer">
            Notice for pay hostel and mess fees before 4/11/2023
            </a> <img src={newgif} alt="new" />
          </li>


          <li>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSdaiAW7rY50CnTVOsIvYg2FRePBdUmN94R1tyAA2XZEI9014w/viewform' target="_blank"  rel="noreferrer">
            Hostel Registration form 2023-24
            </a> <img src={newgif} alt="new" />
          </li>

          <li>
            <a href='https://www.antiragging.in/affidavit_university_form.php' target="_blank"  rel="noreferrer">
          online anti-ragging affidavit for all students (Compulsory) 


            </a> 
          </li>


          <li>
            <a href={h1} rel="noopener">
              Hostel Circular
            </a>
          </li>
          <li>
            <a href={h2}>Girls Hostel Due List 2020-21</a>
          </li>
          <li>
            <a href={h3}>Boys Hostel Due List 2020-21</a>
          </li>
          <li>
            <a href={h4} rel="noopener">
              Medical Certificate
            </a>
          </li>
          <li>
            <a href={h5} rel="noopener">
              Leave Application
            </a>
          </li>
          <li>
            <a href={h6} rel="noopener">
              Hostel Rules
            </a>
          </li>
          <li>
            <a href={h7} rel="noopener">
              Hostel ID Card
            </a>
          </li>
          <li>
            <a href={h8} rel="noopener">
              Hostel Admission Form
            </a>
          </li>
          <li>
            <a href={h9} rel="noopener">
              Gate pass
            </a>
          </li>
          <li>
            <a href={h10} rel="noopener">
              Declaration by the Students
            </a>
          </li>
          <li>
            <a href={h11} rel="noopener">
              ANTI-RAGGING AFFIDAVIT
            </a>
          </li>{" "}
        </ul>
      </div>
      {/* Hostel */}
    </div>
  );
};

export default StudentHostal;
