import React from "react";
import "./SayNoToDrugs.css";
import poster1 from "./Prevention of Drug Abuse (Poster).png";
import poster2 from "./Drug free delhi banner 2 (6x8)_page-0001 (1).jpg";
import GlimpsesVideo from "./Glimpses of the NSS Event (Say No to Drugs).mp4";

const SayNoToDrugs = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="g20-maha-food-festival-heading">
        NSS Event - SAY NO TO DRUGS
      </h2>
      <div className="g20-maha-food-festival-paragrauph">
        <div className="img-container-of-say-no-to-drug-page">
          <img src={poster2} alt="poster" />
        </div>

        {/* Programme Container */}
        <div className="program-container">
          <h1>Awareness Program on </h1>

          <h2>
            {" "}
            Prevention of Drug Use, Its Act, Enforcement, and Awareness
            <br />
            Jointly organized by
          </h2>
          <h3>
            Delhi Police /Crime, DPSRU NSS Unit, and Bhagidari Jan Sahyog Samiti
            (NGO)
          </h3>

          <p>
            <strong>Date:</strong> 20th December, 2024 (Friday) <br />
            <strong>Time:</strong> 2:30 pm onwards <br />
            <strong>Venue:</strong> Dr. G.K. Narayanan Auditorium, DPSRU
          </p>

          <h2>Program Details</h2>
          <table className="program-table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>3:00 pm - 3:05 pm</td>
                <td>Arrival of the Guests on Dias</td>
              </tr>
              <tr>
                <td>3:05 pm - 3:10 pm</td>
                <td>Lamp Lighting and University Song</td>
              </tr>
              <tr>
                <td>3:10 pm - 3:15 pm</td>
                <td>Welcome & Felicitation of the Guests</td>
              </tr>
              <tr>
                <td>3:15 pm - 3:20 pm</td>
                <td>Inaugural Speech by Hon’ble Vice Chancellor, DPSRU</td>
              </tr>
              <tr>
                <td>3:20 pm - 3:25 pm</td>
                <td>Words of Wisdom by Off. Registrar, DPSRU</td>
              </tr>
              <tr>
                <td>3:25 pm - 3:30 pm</td>
                <td>
                  Introduction of Program by Dr. Suresh Kumar, NSS Programme
                  Officer
                </td>
              </tr>
              <tr>
                <td>3:30 pm - 3:45 pm</td>
                <td>
                  Address Note by Guest Speaker Ms. Sajila Maini, Sr.
                  Consultant, Sir Ganga Ram Hospital
                </td>
              </tr>
              <tr>
                <td>3:45 pm - 3:50 pm</td>
                <td>
                  Address Note by Guest of Honor Mr. Vijay Gaur, Chairperson of
                  Bhagidari Jan Sahyog Samiti
                </td>
              </tr>
              <tr>
                <td>3:50 pm - 3:55 pm</td>
                <td>
                  Address Note by Chairperson of the session, Shri Bhism Singh,
                  IPS, DCP/ANTF, Crime Branch
                </td>
              </tr>
              <tr>
                <td>3:55 pm - 4:00 pm</td>
                <td>Address Note by Prof. Harvinder Popli, DPSRU</td>
              </tr>
              <tr>
                <td>4:00 pm - 4:05 pm</td>
                <td>Distribution of Memento to organization/Media partners</td>
              </tr>
              <tr>
                <td>4:05 pm - 4:10 pm</td>
                <td>
                  Address Note by Chief Guest Sh. Devesh Chandra Srivastva IPS,
                  Special Commissioner of Police, Crime Branch
                </td>
              </tr>
              <tr>
                <td>4:10 pm - 4:15 pm</td>
                <td>“Say No to Drugs” Pledge</td>
              </tr>
              <tr>
                <td>4:15 pm - 4:20 pm</td>
                <td>
                  Vote of thanks by Dr. Parul Sharma, NSS Programme Officer
                </td>
              </tr>
              <tr>
                <td>4:20 pm - 4:25 pm</td>
                <td>Nukkad Natak Play</td>
              </tr>
              <tr>
                <td>4:25 pm - 4:30 pm</td>
                <td>National Anthem</td>
              </tr>
              <tr>
                <td>4:30 pm onwards</td>
                <td>
                  Distribution of certificates & refreshments to all
                  participants
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Programme Container */}

<br />
        <div className="say-no-to-drug-image-and-video-container">
          <div className="say-no-to-drug-image-and-video-each-box">
            <iframe
              src={GlimpsesVideo}
              title="GlimpsesVideo"
              allowfullscreen=""
              width="100%"
              height="100%"
           />
          </div>
          <div className="say-no-to-drug-image-and-video-each-box">
          <img src={poster1} alt="poster" />

          </div>
        </div>
      </div>
    </div>
  );
};

export default SayNoToDrugs;
