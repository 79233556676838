import React from 'react'
import './Workshop.css'

import img1 from './workshop-img1.jpg'
import img2 from './workshop-img2.jpg'
import img3 from './workshop-img3.jpg'
import img4 from './workshop-img4.jpg'

const Workshop = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="g20-maha-food-festival-heading">ADJUNCT MECHANISM IN ON FIELD INJURY REHABILITATION: KINESIOTAPING</h2>

    <div className="g20-maha-food-festival-paragrauph">
    The School of Physiotherapy organised a workshop on “ADJUNCT MECHANISM IN ON FIELD INJURY REHABILITATION: KINESIOTAPING” workshop for the MPT and PhD Students on 13th Feb 2023. Dr Kopal Pajnee PhD, Certified Kinesio Taping Practitioner By Kinesiotaping Association, (KTAI, USA) (2015) and currently Heading the Physiothrapy department at  Jan Kalyan Healthcare Centre, was the resource person for the same. He abreasted us with the recent concepts of the Kinesiotaping modality for use in case of injuries as well as disorders.  The principles of usage, the concept of recoil and length, anchor and its typical adhesive used in different muscular, nerve and lymphatic conditions as an adjunct to Physiotherapy. The event was coordinated by Prof Richa H Rai and Dr Sheetal Kalra Head and Associate Professor School of Physiotherapy and Dr Jitender, Dr Varsha Chorsiya as Co Cordinators and Dr Ravinder, Dr Savita, Dr Tabassum, Dr Neha, , Dr Sapna, Dr Parul  as the members of the committee. The event was successfully conducted under the guidance of Prof Ramesh K Goyal Vice Chancellor DPSRU and Dr O.P.Shukla Registrar DPSRU and Advisors- Prof  Harvinder Popli, Prof. Deepti Pandita, Prof. Sharad Wakode.
    </div>

    <h2 className="dpsru-secondary-heading-for-every-page">
      GALLERY
    </h2>

    <div className="workshop-gallery-container">
      <img src={img1} alt="Workshop" />
      <img src={img2} alt="Workshop" />
      <img src={img3} alt="Workshop" />
      <img src={img4} alt="Workshop" />
    
    </div>
    </div>
  )
}

export default Workshop
